import React, { useState, useEffect } from "react";
import { Backdrop, Checkbox, Modal } from "@mui/material";
import Select from "react-select";
import { useStateContext } from "../../context/ContextProvider";
import { selectStyles } from "../_elements/SelectStyles.jsx";
import axios from "../../axoisConfig.js";
import { CiFolderOn } from "react-icons/ci";
import { GoPlusCircle } from "react-icons/go";
import { IoCloseOutline } from "react-icons/io5";
import { TiArrowSortedDown } from "react-icons/ti";
import Menu from "@mui/material/Menu";
import { IoIosImages } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { RiPagesLine } from "react-icons/ri";
import { FaMinus } from "react-icons/fa6";
import { IoIosCrop } from "react-icons/io";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { PiFilmSlateBold } from "react-icons/pi";
import { TbPhoto } from "react-icons/tb";
import { MdEmojiEmotions } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const CreatePost = ({ setCreatePostModal, createPostModal }) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    snapchatCredentials,
    themeBgImg,
  } = useStateContext();
  const [currentTab, setCurrentTab] = useState();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isImages, setIsImages] = useState([]);

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  const style = {
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
  };

  const handleImageChange = (e) => {
    // alert("it is called");
    const file = e.target.files[0];
    if (file) {
      // Store the file
      const fileUrl = URL.createObjectURL(file); // Create a preview URL
      setIsImages((pre) => [...pre, fileUrl]); // Update the state with the preview URL
    }
  };
  return (
    <Modal
      //   keepMounted
      open={createPostModal}
      onClose={() => setCreatePostModal(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        zIndex: 1400,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[40%] h-fit ${
          // currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          currentMode === "dark"
            ? "bg-dark-neu text-white"
            : "bg-white text-black"
        } absolute top-1/2 left-1/2  flex flex-col items-start`}
      >
        <div className="w-full flex flex-col flex-1 overflow-y-scroll px-6 py-6">
          <div className="pb-4 border-b flex justify-between">
            <label htmlFor="">Create Post</label>
            <span
              className="cursor-pointer"
              onClick={() => setCreatePostModal(false)}
            >
              <IoCloseOutline size={18} />
            </span>
          </div>
          <div className="flex gap-4 items-center py-3">
            <div className="w-[50px] h-[50px] rounded-full bg-green-500 flex items-center justify-center text-[24px] font-bold text-gray-700">
              {/* <img src="/assets/female_character.png" alt="page name" /> */}
              M
            </div>
            <div>
              <input
                type="text"
                className="text-[20px] border-none outline-none focus:outline-none"
                placeholder="Write a post..."
              />
            </div>
          </div>
          <div className="flex gap-3 mb-3">
            {isImages?.length > 0 && (
              <>
                {isImages?.map((img) => {
                  return (
                    <img
                      src={img}
                      alt=""
                      className="w-[100px] h-[100px] object-cover"
                    />
                  );
                })}
                <label
                  htmlFor="share-photo"
                  className="w-[100px] h-[100px] border-[2px] border-dashed flex items-center justify-center text-gray-500 cursor-pointer"
                >
                  <FaPlus size={18} />
                </label>
              </>
            )}
          </div>
          <div className="flex gap-2 border-b pb-4">
            <button className="flex items-center gap-2 bg-light rounded-xl p-2">
              <PiFilmSlateBold size={16} />
              Video
            </button>
            <input
              type="file"
              name=""
              className="hidden"
              id="share-photo"
              accept="image/*"
              onChange={handleImageChange}
            />
            <label
              htmlFor="share-photo"
              className="flex items-center gap-2 bg-light rounded-xl p-2 cursor-pointer"
            >
              <TbPhoto size={16} />
              Share a photo
            </label>
            {/* <button className="flex items-center gap-2 bg-light rounded-xl p-2">
              <MdEmojiEmotions
                size={16}
                color="#eab308"
                enableBackground={"black"}
              />
              Feeling/activitives
            </button> */}
            {/* <button className="flex items-center gap-2 bg-light rounded-xl p-2">
              <BsThreeDots size={16} color="#374151" />
            </button> */}
          </div>
          <div className="flex justify-end w-full pt-4">
            <button className="bg-[#365899] text-white p-2">Publish</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreatePost;
