import React, { useState } from "react";
import { TfiClose } from "react-icons/tfi";
import { RiFacebookBoxLine } from "react-icons/ri";
import { SlSocialInstagram } from "react-icons/sl";
import { PiHandshake } from "react-icons/pi";
import { GoSearch } from "react-icons/go";
import { Backdrop, Checkbox, Modal } from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";
import { LiaFacebookSquare } from "react-icons/lia";
import { TiArrowSortedDown } from "react-icons/ti";
import { FormControlLabel } from "@mui/material";
import SelectPostFilter from "./selectPostFilter";
import { DataGrid } from "@mui/x-data-grid";
import { FaRegImage } from "react-icons/fa6";
import { SlLike } from "react-icons/sl";
import { GoComment } from "react-icons/go";
import { PiShareFat } from "react-icons/pi";

import { Box } from "@mui/material";
const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const SelectPost = ({ selectPostModal, setSelectPostModal }) => {
  const {
    currentMode,
    themeBgImg,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    DataGridStyles,
  } = useStateContext();
  const [currentTab, setCurrentTab] = useState("facebook");
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  const postInSights = [
    {
      icon: <SlLike size={16} />,
      value: 0,
    },
    {
      icon: <GoComment size={16} />,
      value: 3,
    },
    {
      icon: <PiShareFat size={16} />,
      value: 5,
    },
  ];

  const rows = [
    {
      post: "hello",
      post_id: "48923032",
      source: "Feed",
      media: "Status",
      dateCreated: "23 Nov 2024",
      id: 1,
    },
    {
      post: "hello",
      post_id: "48923032",
      source: "Feed",
      media: "Status",
      dateCreated: "23 Nov 2024",
      id: 2,
    },
    {
      post: "hello",
      post_id: "48923032",
      source: "Feed",
      media: "Status",
      dateCreated: "23 Nov 2024",
      id: 3,
    },
    {
      post: "hello",
      post_id: "48923032",
      source: "Feed",
      media: "Status",
      dateCreated: "23 Nov 2024",
      id: 4,
    },
  ];

  const columns = [
    {
      field: "post",
      headerName: "Facebook post",
      headerAlign: "left",
      renderHeader: (params) => {
        return <div className=" ">{params.colDef.headerName}</div>;
      },
      minWidth: 300,
      renderCell: (params) => (
        <div className="flex w-full text-left h-full py-3 pl-2 gap-3">
          <div className=" bg-gray-200 w-[90px] h-full flex items-center justify-center">
            <FaRegImage size={26} color="gray" />
          </div>
          <div className="flex-1 flex flex-col py-3 justify-between">
            <h4>{params?.value}</h4>
            <div className="flex gap-3">
              {postInSights?.map((post) => {
                return (
                  <div className="flex items-center gap-2">
                    {post?.icon}
                    <span>{post?.value}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ),
    },
    {
      field: "post_id",
      headerName: "Post ID",
      headerAlign: "left",
      headerClassName: "",
      renderHeader: (params) => {
        return (
          <div className="text-left w-full font-semibold">
            {params.colDef.headerName}
          </div>
        );
      },
      renderCell: (params) => {
        return <div className="w-full !text-left">{params?.value}</div>;
      },
      minWidth: 150,
    },

    {
      field: "source",
      headerName: "Source",
      headerAlign: "center",
      minWidth: 110,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        console.log("budget: ", params);
        return <div className="w-full text-center">{params?.value}</div>;
      },
    },

    {
      field: "media",
      headerName: "Media",
      headerAlign: "center",
      minWidth: 120,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return <div className="w-full text-center">{params?.value}</div>;
      },
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      headerAlign: "center",
      minWidth: 120,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return <div className="w-full text-center">{params?.value}</div>;
      },
    },
  ];
  return (
    <Modal
      //   keepMounted
      open={selectPostModal}
      onClose={() => setSelectPostModal(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        zIndex: 1400,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[60%] h-fit pb-5 px-2 ${
          // currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          currentMode === "dark"
            ? "bg-dark-neu text-white"
            : "bg-white text-black"
        } absolute top-1/2 left-1/2  rounded-lg flex flex-col items-start`}
      >
        <div className="w-full max-h-full  py-4 px-6 overflow-y-auto">
          <div className="flex justify-between">
            <div>
              <h3
                className={`text-[16px] font-semibold mb-1 tracking-wide ${
                  currentMode === "dark" ? "text-white" : "text-gray-900"
                } `}
              >
                Select post
              </h3>
            </div>

            <div
              className="pt-1 cursor-pointer"
              onClick={() => setSelectPostModal(false)}
            >
              <TfiClose />
            </div>
          </div>
          <div className="flex gap-4 pt-7 pb-4 ">
            <button
              className={`p-2 px-3 rounded-md flex  items-center gap-1 ${
                currentTab === "facebook" && "bg-[#C3DCF5] text-blue-800"
              }`}
            >
              {/* <LiaFacebookSquare size={20} color="#596774" /> */}
              <div
                onClick={() => setCurrentTab("facebook")}
                className="bg-no-repeat inline-block w-[22px] h-[17px] "
                style={{
                  backgroundImage: `url('https://static.xx.fbcdn.net/rsrc.php/v3/yv/r/cX7DKT2CkS9.png?_nc_eui2=AeH5yspQWneUptvKil0lNs-PnyzUMinCbu6fLNQyKcJu7gbOaCxldSmzbz6EmiGhDyELMYzohemRX9EswFsBy_xw')`,
                  backgroundPosition: "-247px -837px",
                  backgroundSize: "auto",
                }}
              ></div>
              <span className="text-[14px]">Facebook</span>
            </button>
            <button
              onClick={() => setCurrentTab("instagram")}
              className={`p-2 px-3 rounded-md flex  items-center gap-3 cursor-auto ${
                currentTab === "instagram" && "bg-[#C3DCF5] text-blue-800"
              }`}
            >
              <SlSocialInstagram className=" text-gray-700" size={16} />
              <span className="text-[14px]">Instagram</span>
            </button>
            <button
              onClick={() => setCurrentTab("partner_content")}
              className={`p-2 px-3 rounded-md flex  items-center gap-3 cursor-pointer ${
                currentTab === "partner_content" && "bg-[#C3DCF5] text-blue-800"
              }`}
            >
              <PiHandshake className=" text-gray-700" size={16} />

              <span className="text-[14px]">Partner Content</span>
            </button>
          </div>
          <hr className="p-0 m-0" />
          <div className="pt-4 px-5">
            <p className="p-0 m-0">Filter by:</p>
            <div className="flex gap-1 items-start">
              {/* <select
                className="h-[34px] border-[1px] border-gray-400 rounded"
                name=""
                id=""
              >
                <option value="">All Posts</option>
              </select> */}
              {/*  */}
              <SelectPostFilter />

              <div className="flex pl-2 items-center border-[1px] border-gray-400 rounded flex-1">
                <GoSearch className="h-5 w-5 " />
                <input
                  type="text"
                  className={` outline-none rounded pl-2 py-3 flex-1 ${getSummaryBgClass()}`}
                  placeholder="Post image or video IDs, or other keywords"
                />
              </div>
            </div>
          </div>
          {/* table */}
          <div className="pt-2 px-5 overflow-y-auto h-[300px]">
            <Box
              className={`closed-datatable ${currentMode}-mode-datatable`}
              sx={{
                ...DataGridStyles,
                position: "relative",
                marginBottom: "50px",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <DataGrid
                rows={rows}
                // loading={loading}
                columns={columns}
                pageSize={5}
                // checkboxSelection
                // disableSelectionOnClick
                autoHeight
                rowHeight={90}
                // selectionModel={selectedRows?.adSets}
                // onSelectionModelChange={(rows) =>
                //   setSelectedRows((pre) => ({ ...pre, adSets: rows }))
                // }
              />
            </Box>
          </div>
        </div>
        <div className="flex items-center justify-end gap-3 w-full pt-3 px-4 border-t-[2px]">
          <button
            // onClick={() => setCreateAdModal(false)}
            className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium ${
              currentMode == "dark" ? "bg-dark-neu text-white" : "bg-white"
            } `}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              // setCreateAdModal(false);
              // //   setIsSingleAd(false);
              // setIsSingleAd(1);
              // setAdDetails(null);
            }}
            className="p-3 px-7 rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
            // disabled={!selectedCampaign}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SelectPost;
