import React, { useState, useEffect } from "react";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineDelete } from "react-icons/md";
import DeleteConfirm from "./DeleteConfirmation";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
// import { deliveryStatus } from "./deliveryStatuses";
import CustomtomSwitch from "./customSwitch";

const token = localStorage?.getItem("auth-token");

const deliveryStatus = [
  {
    code: "INVALID_NOT_EFFECTIVE_ACTIVE",
    label: "Campaign is inactive",
  },
  {
    code: "INVALID_NOT_ACTIVE",
    label: "Not Devlivering",
  },
];
// const Android12Switch = styled(Switch)(({ theme }) => ({
//   padding: 8,
//   "& .MuiSwitch-track": {
//     borderRadius: 22 / 2,
//     "&::before, &::after": {
//       content: '""',
//       position: "absolute",
//       top: "50%",
//       transform: "translateY(-50%)",
//       width: 16,
//       height: 16,
//     },
//     "&::before": {
//       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
//         theme.palette.getContrastText(theme.palette.primary.main)
//       )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
//       left: 12,
//     },
//     "&::after": {
//       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
//         theme.palette.getContrastText(theme.palette.primary.main)
//       )}" d="M19,13H5V11H19V13Z" /></svg>')`,
//       right: 12,
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxShadow: "none",
//     width: 16,
//     height: 16,
//     margin: 2,
//   },
// }));

// Columns definition

const CompaignsTable = ({
  setIsSingleCampaign,
  setCampaignDetails,
  oldObjectives,
  compaigns,
  setComapaigns,
  getAllCompaigns,
  selectedRows = { selectedRows },
  setSelectedRows = { setSelectedRows },
  loading,
  searchQuery,
}) => {
  // const [compaigns, setComapaigns] = useState([]);
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    DataGridStyles,
  } = useStateContext();
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);

  const filteredCampaigns = compaigns.filter((row) =>
    Object.values(row).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  async function deleteCampaign(id) {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/delete/campaign`,
        {
          access_token: snapchatCredentials?.access_token,
          campaign_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      getAllCompaigns();
      toast.success("Campaign is successfully deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log(error, "error");
      toast.error("Campaign can't be deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  async function updateCampaign(campaignDetails, status) {
    console.log(campaignDetails, "details");
    try {
      await axios.post(
        `${BACKEND_URL}/snapchat/update/campaign`,
        {
          campaign_id: campaignDetails?.id,
          access_token: snapchatCredentials?.access_token,
          name: campaignDetails?.name,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
          status: status ? "ACTIVE" : "PAUSED",
          start_time: campaignDetails?.start_time,
          objective: campaignDetails?.objective,
          ...(campaignDetails?.end_time && {
            end_time: campaignDetails.end_time,
          }),
          ...(campaignDetails?.daily_budget_micro && {
            daily_budget_micro: campaignDetails?.daily_budget_micro / 1000000,
          }),
          ...(campaignDetails?.lifetime_spend_cap_micro && {
            lifetime_spend_cap_micro:
              campaignDetails?.lifetime_spend_cap_micro / 1000000,
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      toast.success(" Campaign is Successfully Updated", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      getAllCompaigns();
    } catch (error) {
      toast.error("Unable to update campaign", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(error, "this is an error");
      if (error.response?.data?.errors) {
        Object.values(error.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    }
  }

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      headerClassName: "pl-5",
      renderHeader: (params) => {
        return (
          <div className="pl-12 font-medium">{params.colDef.headerName}</div>
        ); // Apply padding manually
      },
      renderCell: (params) => (
        <Name
          value={params?.value}
          onEdit={() => {
            setIsSingleCampaign(
              oldObjectives?.awareness?.find(
                (obj) => obj.identifier == params?.row?.objective
              ) ||
                oldObjectives?.consideration?.find(
                  (obj) => obj.identifier == params?.row?.objective
                ) ||
                oldObjectives?.conversions?.find(
                  (obj) => obj.identifier == params?.row?.objective
                )
            );
            setCampaignDetails(params?.row);
          }}
          onDelete={() => {
            // deleteCampaign(params?.row?.id,params?.value);
            setOpenDeleteConfirm({ id: params?.row?.id, name: params?.value });
          }}
        />
      ),
      minWidth: 250,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      minWidth: 150,
      renderHeader: (params) => {
        return <div className=" font-medium">{params.colDef.headerName}</div>; // Apply padding manually
      },
      renderCell: (params) => (
        <CustomtomSwitch
          checked={params.row.status == "PAUSED" ? false : true}
          onChange={(e) => updateCampaign(params?.row, e?.target?.checked)}
        />
      ),
    },
    {
      field: "delivery_status",
      headerName: "Delivery Status",
      headerAlign: "left",

      minWidth: 200,
      renderHeader: (params) => {
        return (
          <div className="text-left w-full font-medium">
            {params.colDef.headerName}
          </div>
        ); // Apply padding manually
      },
      renderCell: (params) => {
        return (
          <div className="flex text-left w-full items-center gap-3">
            <div className="w-[8px] h-[8px] bg-[#848688] rounded-full"></div>
            <div className="flex flex-col items-start">
              {params?.value
                ?.slice(0, 2)
                ?.map((val) =>
                  deliveryStatus?.find((status) => status?.code == val)
                    ?.label ? (
                    <div>
                      {
                        deliveryStatus?.find((status) => status?.code == val)
                          ?.label
                      }
                    </div>
                  ) : (
                    <div>{val?.replace(/_/g, " ")}</div>
                  )
                )}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Box
          className={`closed-datatable ${currentMode}-mode-datatable`}
          sx={{
            ...DataGridStyles,
            "& .MuiDataGrid-columnHeaders": {
              ...DataGridStyles["& .MuiDataGrid-columnHeaders"],
              backgroundColor: "transparent",
              color: currentMode === "dark" ? "white" : "black",
              fontFamily:
                "Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif",
              borderTop: "1px solid rgba(224, 224, 224, 1)",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            },
            "& .MuiButtonBase-root": {
              ...DataGridStyles["& .MuiButtonBase-root"],
              fontFamily:
                "Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif",
            },
            "& .MuiDataGrid-virtualScroller": {
              ...DataGridStyles["& .MuiDataGrid-virtualScroller"],
              fontFamily:
                "Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif",
            },
            position: "relative",
            marginBottom: "50px",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <DataGrid
            loading={loading}
            rows={filteredCampaigns}
            columns={columns}
            pageSize={5}
            checkboxSelection
            disableSelectionOnClick
            autoHeight
            selectionModel={selectedRows?.campaigns}
            onSelectionModelChange={(rows) => {
              setSelectedRows((pre) => ({ ...pre, campaigns: rows }));
              console.log(rows, "rows");
            }}
          />
        </Box>
      </div>
      <DeleteConfirm
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        message={` Are you sure you want to delete campaign ${openDeleteConfirm?.name}? `}
        onDelete={deleteCampaign}
        title="Confirm Delete Campaign"
      />
    </>
  );
};

export default CompaignsTable;

const Name = ({ value, onEdit, onDelete }) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const { currentMode } = useStateContext();
  return (
    <>
      <div
        className="pl-11 w-full text-left h-full flex items-start justify-center flex-col"
        onMouseEnter={() => setIsMouseEnter(true)}
        onMouseLeave={() => setIsMouseEnter(false)}
      >
        <p>{value}</p>
        {
          <div
            className="flex gap-3 transition-all duration-[200ms] ease-in-out"
            style={{
              transform: isMouseEnter ? "translateY(0)" : "translateY(100%)",
              opacity: isMouseEnter ? 1 : 0,
            }}
          >
            <button
              className={`text-${
                currentMode === "dark" ? "white" : "gray-600"
              } hover:text-${currentMode === "dark" ? "gray-600" : "gray-900"}`}
              onClick={onEdit}
            >
              Edit
            </button>
            <button
              className={`text-${
                currentMode === "dark" ? "white" : "gray-600"
              } hover:text-${
                currentMode === "dark" ? "gray-600" : "gray-900"
              } flex gap-2 items-center`}
              onClick={onDelete}
            >
              Delete <MdOutlineDelete size={16} />
            </button>
          </div>
        }
      </div>
    </>
  );
};
