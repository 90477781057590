import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
import { useStateContext } from "../../context/ContextProvider";
import HeadingTitle from "../../Components/_elements/HeadingTitle";
import AdsManager from "../../Components/snapchatMarketing/AdsManager";
import { useParams } from "react-router-dom";
import SnapchatMembers from "./Members";
import SnapchatAdAccounts from "./adAccounts";
import CreativeLibrary from "./creativeLibrary";
import Header from "../../Components/snapchatMarketing/Header";

const pages = {
  manageAds: <AdsManager />,
  members: <SnapchatMembers />,
  adAccounts: <SnapchatAdAccounts />,
  creativeLibrary: <CreativeLibrary />,
};

const SnapChatMarketing = () => {
  const [loading, setloading] = useState(false);
  const params = useParams();
  const {
    currentMode,
    setopenBackDrop,
    BACKEND_URL,
    pageState,
    t,
    themeBgImg,
  } = useStateContext();

  const { module } = params;

  console.log("Booked State: ", pageState);

  return (
    <>
      <div className="flex min-h-screen snapchat-fontFamily">
        {loading ? (
          <Loader />
        ) : (
          <div
            className={`w-full mt-2 ${
              !themeBgImg &&
              (currentMode === "dark" ? "bg-dark" : "bg-[#F9F9FA]")
            }`}
          >
            <Header />
            {pages[module]}
          </div>
        )}
      </div>
    </>
  );
};

export default SnapChatMarketing;
