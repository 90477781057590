import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { TiArrowSortedDown } from "react-icons/ti";
import { useStateContext } from "../../context/ContextProvider";

function SelectPostFilter() {
  const { currentMode } = useStateContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (label) => {
    setSelectedOptions((prev) =>
      prev.includes(label)
        ? prev.filter((option) => option !== label)
        : [...prev, label]
    );
  };

  return (
    <div>
      <button
        onClick={handleOpen}
        className={`p-3 px-3 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3  ${
          currentMode == "dark" ? "bg-dark-neu text-white" : "bg-white"
        } `}
      >
        <span>Select Options</span>
        <TiArrowSortedDown size={16} />
      </button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          zIndex: 999999,
          //   px: 4,
        }}
      >
        {[
          "All post types",
          "Published posts",
          "Ads posts",
          "Schedule posts",
          "Available posts only",
          "Option 3",
        ].map((label) => (
          <MenuItem
            key={label}
            sx={{
              p: 0,
              px: 2, // Remove padding around each item
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOptions.includes(label)}
                  onChange={() => handleCheckboxChange(label)}
                />
              }
              label={label}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default SelectPostFilter;
