import { useState, useEffect } from "react";
import { useStateContext } from "../../context/ContextProvider";
import { socket } from "../App";
import { BsArrowsFullscreen } from "react-icons/bs";
import Loader from "../../Components/Loader";
import { IconButton } from "@mui/material";
import ProgressBar from "../../Components/_elements/Progressbar";

import {
  BsArrowLeftCircleFill,
  BsArrowRightCircleFill,
  BsFillBookmarkStarFill,
  BsFillLockFill,
  BsFillStarFill,
} from "react-icons/bs";

const TodayCallLogs = () => {
  const [noData, setNoData] = useState(false);
  const [callLogs, setCallLogs] = useState([]);
  // const [targetData, setTargetData] = useState([]);
  // const [noTargetData, setNoTargetData] = useState(false);
  const [loading, setLoading] = useState(true);
  const { currentMode, User } = useStateContext();
  // const [slide, setSlide] = useState(0);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setSlide(slide === 0 ? slides.length - 1 : slide - 1);
  //   }, 60 * 1000);
  // });

  // const nextSlide = () => {
  //   setSlide(slide === slides.length - 1 ? 0 : slide + 1);
  // };

  // const prevSlide = () => {
  //   setSlide(slide === 0 ? slides.length - 1 : slide - 1);
  // };

  // const slides = [
  //   {
  //     heading: "CALL LOGS",
  //   },
  //   {
  //     heading: "MONTHLY TARGET",
  //   },
  // ];

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date();

  useEffect(() => {
    if (User && socket) {
      socket.emit("get-call-logs");
      // socket.emit("get-target-data");

      socket.on("call-logs", (data) => {
        if (data) {
          console.log("call logs data:: ", data);
          if (data.length > 0) {
            setNoData(false);
            setLoading(false);
            setCallLogs(data);
          } else {
            setNoData(true);
          }
        }
        setLoading(false);
      });

      // socket.on("target-data", (data) => {
      //   if (data) {
      //     console.log(data);
      //     if (data.length > 0) {
      //       setNoTargetData(false);
      //       setLoading(false);
      //       setTargetData(data);
      //     } else {
      //       setNoTargetData(true);
      //     }
      //   }
      //   setLoading(false);
      // });
    }
  }, [User, socket]);

  function requestFullScreen(element) {
    var requestMethod =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullScreen;
    if (requestMethod) {
      // Native full screen.
      requestMethod.call(element);
    } else if (typeof window.ActiveXObject !== "undefined") {
      // Older IE.
      var wscript = new window.ActiveXObject("WScript.Shell");
      if (wscript !== null) {
        wscript.SendKeys("{F11}");
      }
    }
  }
  return (
    <div
      className={`text-xl bg-[#242c3a] text-white`}
      style={{
        height: "100vh",
        width: "100vw",
        backgroundImage: "radial-gradient(circle, #242c3a, #000000)"
      }}
    >
      {/* WATERMARK */}
      <div className="z-0 absolute top-0 left-0 w-full h-full flex items-center justify-center">
        <img
          src={"/HikalLogo.png"}
          className="max-h-[70vh] max-w-[70vw] h-auto w-auto"
        />
      </div>
      {/* FULL SCREEN BUTTON */}
      <div className="fixed top-5 right-5"
        sttyle={{
          zIndex: 5
        }}>
        <IconButton onClick={() => requestFullScreen(document.body)}>
          <BsArrowsFullscreen size={16} color={"#EEEEEE"} />
        </IconButton>
      </div>
      {/* TITLE */}
      <div className="fixed top-5 left-5 right-5 w-full font-semibold translate-x-0 translate-y-0">
        CALLS COUNT
      </div>

      {/* <div className="carousel px-5 pb-5 mt-[7vh] "> */}
      {" "}
      {/* overflow-hidden */}
      {/* <BsArrowLeftCircleFill
          className="arrow arrow-left"
          onClick={prevSlide}
        /> */}
      {/* {slides.map((item, idx) => {
          return ( */}
      <div
        className="w-full h-full p-5 overflow-y-scroll mt-6 pt-6"
        style={{
          height: "100vh",
          width: "100vw"
        }}
      >
        <>
          {/* CALL LOGS  */}
          <>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-x-3 gap-y-3 pb-3 ">
                  {noData === false &&
                    callLogs?.length > 0 &&
                    callLogs?.map((call, index) => {
                      return (
                        <div className="blur-bg-dark p-4"
                          style={{
                            zIndex: 0,
                          }}>
                          <div className="flex flex-col justify-between items-center gap-4 h-full">
                            <div className="px-1 font-bold text-lg text-center">
                              {call?.userName}
                            </div>
                            {/* LOGS */}
                            <div className="w-full flex flex-col gap-3">
                              <div className={`blur-bg-dark p-2 w-full`} >
                                <h6 className="mb-1 text-center text-lg font-semibold">
                                  Outgoing
                                </h6>
                                <hr></hr>
                                <div className="block gap-3 mt-2">
                                  <div>
                                    <h1 className="text-lg">
                                      ANSWERED&nbsp;
                                      <span
                                        className="font-bold float-right"
                                        style={{ color: "#00A67D" }}
                                      >
                                        {call?.answered || 0}
                                      </span>
                                    </h1>
                                  </div>
                                  <div>
                                    <h1 className="text-lg">
                                      NOT ANSWERED&nbsp;
                                      <span
                                        className="font-bold float-right"
                                        style={{ color: "#df2938" }}
                                      >
                                        {call?.notanswered || 0}
                                      </span>
                                    </h1>
                                  </div>
                                </div>
                              </div>
                              <div className={`blur-bg-dark p-2 w-full`} >
                                <h6 className="mb-1 text-center text-lg font-semibold">
                                  Incoming
                                </h6>
                                <hr></hr>
                                <div className="block gap-3 mt-2">
                                  <div>
                                    <h1 className="text-lg">
                                      RECEIVED&nbsp;
                                      <span
                                        className="font-bold float-right"
                                        style={{ color: "#00A67D" }}
                                      >
                                        {call.received || 0}
                                      </span>
                                    </h1>
                                  </div>
                                  <div>
                                    <h1 className="text-lg">
                                      MISSED&nbsp;
                                      <span
                                        className="font-bold float-right"
                                        style={{ color: "#df2938" }}
                                      >
                                        {call.missed || 0}
                                      </span>
                                    </h1>
                                  </div>
                                </div>
                              </div>
                              <div className="blur-bg-blue p-2 w-full">
                                <div>
                                  <h1 className="text-lg">
                                    Total Leads&nbsp;
                                    <span
                                      className="font-bold float-right"
                                      style={{ color: "#FFFFFF" }}
                                    >
                                      {call.unique_lead_contacts || 0}
                                    </span>
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {noData === true && (
                  <div className="flex flex-col items-center justify-center h-[80vh] ">
                    <img
                      src="./no_data.png"
                      alt="No data Illustration"
                      className="w-[500px] h-[500px] object-cover"
                    />
                  </div>
                )}
              </>
            )}
          </>
          {/* // ) : item.heading === "MONTHLY TARGET" ? (
                //   <>
                //     {loading ? (
                //       <Loader />
                //     ) : (
                //       <>
                //       <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
                //         {noTargetData === false &&
                //         targetData?.length > 0 &&
                //         targetData?.map((target, index) => {
                //           return (
                //             <div
                //               className={`${
                //                 currentMode === "dark"
                //                   ? "bg-[#000000]"
                //                   : "bg-[#FFFFFF]"
                //               } p-3 rounded-lg shadow-md space-y-2`}
                //             >
                //               <div className="mb-2 flex">
                //                 <div>
                //                   <img
                //                     src={
                //                       target?.profile_picture
                //                         ? target?.profile_picture
                //                         : "/assets/user.png"
                //                     }
                //                     height={80}
                //                     width={80}
                //                     className={`rounded-sm object-cover relative mr-3`}
                //                     alt=""
                //                   />
                //                 </div>
                //                 <div className="space-y-2 p-1">
                //                   <div className="font-semibold text-main-red-color">
                //                     {target.userName}
                //                   </div>
                //                   <div className="grid grid-cols-8 flex items-center">
                //                     <div className="flex items-center justify-center bg-[#EEEEEE] rounded-full w-8 h-8 mr-2">
                //                       <BsFillBookmarkStarFill
                //                         size={20}
                //                         color={"#777777"}
                //                         className="p-1"
                //                       />
                //                     </div>
                //                     <div className="col-span-7">
                //                       {target.booked <= 1 ? (
                //                         <>
                //                           <span className="font-bold">
                //                             {target.booked}
                //                           </span>{" "}
                //                           booked unit
                //                         </>
                //                       ) : (
                //                         <>
                //                           <span className="font-bold">
                //                             {target.booked}
                //                           </span>{" "}
                //                           booked units
                //                         </>
                //                       )}
                //                     </div>
                //                   </div>
                //                   <div className="grid grid-cols-8 flex items-center">
                //                     <div className="flex items-center justify-center bg-[#EEEEEE] rounded-full w-8 h-8 mr-2">
                //                       <BsFillLockFill
                //                         size={20}
                //                         color={"#777777"}
                //                         className="p-1"
                //                       />
                //                     </div>
                //                     <div className="col-span-7">
                //                       {target.month_closed <= 1 ? (
                //                         <>
                //                           <span className="font-bold">
                //                             {target.month_closed}
                //                           </span>{" "}
                //                           deal in {monthNames[d.getMonth()]}{" "}
                //                         </>
                //                       ) : (
                //                         <>
                //                           <span className="font-bold">
                //                             {target.month_closed}
                //                           </span>{" "}
                //                           deals in {monthNames[d.getMonth()]}{" "}
                //                         </>
                //                       )}
                //                     </div>
                //                   </div>
                //                   <div className="grid grid-cols-8 flex items-center">
                //                     <div className="flex items-center justify-center bg-[#EEEEEE] rounded-full w-8 h-8 mr-2">
                //                       <BsFillStarFill
                //                         size={20}
                //                         color={"#777777"}
                //                         className="p-1"
                //                       />
                //                     </div>
                //                     <div className="col-span-7">
                //                       <span className="font-bold">
                //                         AED {formatNumber(Number(target.target))}
                //                       </span>{" "}
                //                       target
                //                     </div>
                //                   </div>
                //                 </div>
                //               </div>
                //               <ProgressBar
                //                 bgcolor="#DA1F26"
                //                 height="25px"
                //                 progress={(
                //                   (target.month_closed_amount / target.target) *
                //                   100
                //                 ).toFixed(1)}
                //                 progresswidth={
                //                   target.month_closed_amount >= target.target
                //                     ? 100.0
                //                     : (target.month_closed_amount / target.target) *
                //                       100
                //                 }
                //               />
                //             </div>
                //           );
                //         })}

                //       </div>
                //       {noTargetData === true && (
                //         <div className="flex flex-col items-center justify-center h-[80vh] ">
                //           <img
                //             src="./no_data.png"
                //             alt="No data Illustration"
                //             className="w-[500px] h-[500px] object-cover"
                //           />
                //         </div>
                //       )}
                //     </>
                //     )}
                //   </> */}

          {/* ) : (
                  <></>
                )} */}
        </>
      </div>
      {/* );
        })} */}
      {/* <BsArrowRightCircleFill
          className="arrow arrow-right"
          onClick={nextSlide}
        /> */}
      {/* </div> */}
    </div>
  );
};
export default TodayCallLogs;
