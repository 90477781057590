// // import React, { useState } from "react";
// // import {
// //   Box,
// //   FormControlLabel,
// //   FormLabel,
// //   Menu,
// //   Radio,
// //   RadioGroup,
// // } from "@mui/material";
// // import { selectStyles } from "../_elements/SelectStyles";
// // import { useStateContext } from "../../context/ContextProvider";
// // import Select from "react-select";
// // import { FormControl } from "@mui/base";
// // import { IoSearch } from "react-icons/io5";
// // import DetailedTargeting from "./DetailTargeting";
// // import { RxCross2 } from "react-icons/rx";
// // import { RiArrowRightSFill } from "react-icons/ri";
// // import { MdOutlineArrowDropDown } from "react-icons/md";

// // const placements = [
// //   {
// //     header: "Feeds",
// //     para: "Get high visibility for your business with ads in feeds",
// //     childs: [
// //       "Facebook Feed",
// //       "Facebook profile feed",
// //       "Instagram feed",
// //       "Instagram profile feed",
// //       "Facebook Marketplace",
// //       "Facebook video feeds",
// //       "Facebook right column",
// //       "Instagram Explore",
// //       "Instagram Explore home",
// //       "Messenger inbox",
// //       "Facebook Business Explore",
// //     ],
// //   },
// //   {
// //     header: "Stories and Reels",
// //     para: "Tell a rich, visual story with immersive, full-screen vertical ads",
// //     childs: [
// //       "Instagram Stories",
// //       "Facebook Stories",
// //       "Messenger Stories",
// //       "Instagram Reels",
// //       "Facebook Reels",
// //     ],
// //   },
// //   {
// //     header: "In-stream ads for videos and reels",
// //     para: "Reach people before, during or after they watch a video or reel",
// //     childs: ["Facebook in-stream videos", "Ads on Facebook Reels"],
// //   },
// //   {
// //     header: "Search results",
// //     para: "Get visibility for your business as people search",
// //     childs: ["Facebook search results", "Instagram search results"],
// //   },
// //   {
// //     header: "Messages",
// //     para: "Send offers or updates to people who are already connected to your business",
// //     childs: ["Messenger sponsored messages"],
// //   },
// //   {
// //     header: "Apps and sites",
// //     para: "Expand your reach with ads in external apps and websites",
// //     childs: [
// //       "Audience Network native, banner and interstitial",
// //       "Audience Network rewarded videos",
// //     ],
// //   },
// // ];

// // const Audiences = () => {
// //   const { currentMode, primaryColor, themeBgImg } = useStateContext();

// //   const getSummaryBgClass = () => {
// //     return currentMode === "dark"
// //       ? themeBgImg
// //         ? "blur-bg-dark text-white"
// //         : "bg-dark text-white"
// //       : themeBgImg
// //       ? "blur-bg-light text-gray-800"
// //       : "bg-white text-gray-800";
// //   };

// //   return (
// //     <>
// //       <div>
// //         {placements?.map((placement) => {
// //           return (
// //             <div>
// //               <div>
// //                 <span>
// //                   <RiArrowRightSFill />
// //                 </span>
// //                 {placement?.header}
// //                 <span>
// //                   <input type="checkbox" name="" id="" />
// //                 </span>
// //               </div>
// //               <ul>
// //                 {placement?.childs?.map((child) => {
// //                   return (
// //                     <li>
// //                       <span>{child}</span>{" "}
// //                       <span>
// //                         <input type="checkbox" name="" id="" />
// //                       </span>
// //                     </li>
// //                   );
// //                 })}
// //               </ul>
// //             </div>
// //           );
// //         })}
// //       </div>
// //     </>
// //   );
// // };

// // export default Audiences;
// import React, { useState } from "react";
// import { RiArrowRightSFill } from "react-icons/ri";
// import { RiArrowDownSFill } from "react-icons/ri";

// const placements = [
//   {
//     header: "Feeds",
//     para: "Get high visibility for your business with ads in feeds",
//     childs: [
//       "Facebook Feed",
//       "Facebook profile feed",
//       "Instagram feed",
//       "Instagram profile feed",
//       "Facebook Marketplace",
//       "Facebook video feeds",
//       "Facebook right column",
//       "Instagram Explore",
//       "Instagram Explore home",
//       "Messenger inbox",
//       "Facebook Business Explore",
//     ],
//   },
//   {
//     header: "Stories and Reels",
//     para: "Tell a rich, visual story with immersive, full-screen vertical ads",
//     childs: [
//       "Instagram Stories",
//       "Facebook Stories",
//       "Messenger Stories",
//       "Instagram Reels",
//       "Facebook Reels",
//     ],
//   },
//   {
//     header: "In-stream ads for videos and reels",
//     para: "Reach people before, during or after they watch a video or reel",
//     childs: ["Facebook in-stream videos", "Ads on Facebook Reels"],
//   },
//   {
//     header: "Search results",
//     para: "Get visibility for your business as people search",
//     childs: ["Facebook search results", "Instagram search results"],
//   },
//   {
//     header: "Messages",
//     para: "Send offers or updates to people who are already connected to your business",
//     childs: ["Messenger sponsored messages"],
//   },
//   {
//     header: "Apps and sites",
//     para: "Expand your reach with ads in external apps and websites",
//     childs: [
//       "Audience Network native, banner and interstitial",
//       "Audience Network rewarded videos",
//     ],
//   },
// ];

// const Placements = () => {
//   const [expandedSections, setExpandedSections] = useState({});

//   const toggleSection = (index) => {
//     setExpandedSections((prevState) => ({
//       ...prevState,
//       [index]: !prevState[index],
//     }));
//   };

//   return (
//     <div>
//       {placements.map((placement, index) => (
//         <div key={index} className="mb-4 border rounded p-2">
//           <div
//             className="flex justify-between items-center cursor-pointer"
//             onClick={() => toggleSection(index)}
//           >
//             <div className="flex items-center">
//               <span className="text-lg">
//                 {expandedSections[index] ? (
//                   <RiArrowDownSFill />
//                 ) : (
//                   <RiArrowRightSFill />
//                 )}
//               </span>
//               <div>
//                 <h3 className="ml-2 font-semibold">{placement.header}</h3>
//                 <p>{placement?.para}</p>
//               </div>
//             </div>
//             <input type="checkbox" />
//           </div>
//           {expandedSections[index] && (
//             <ul className="mt-2 pl-6">
//               {placement.childs.map((child, childIndex) => (
//                 <li
//                   key={childIndex}
//                   className="flex justify-between items-center py-2"
//                 >
//                   <span>{child}</span>
//                   <input type="checkbox" />
//                 </li>
//               ))}
//             </ul>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Placements;

import React, { useState } from "react";
import { RiArrowRightSFill, RiArrowDownSFill } from "react-icons/ri";

const placements = [
  {
    header: "Feeds",
    para: "Get high visibility for your business with ads in feeds",
    childs: [
      "Facebook Feed",
      "Facebook profile feed",
      "Instagram feed",
      "Instagram profile feed",
      "Facebook Marketplace",
      "Facebook video feeds",
      "Facebook right column",
      "Instagram Explore",
      "Instagram Explore home",
      "Messenger inbox",
      "Facebook Business Explore",
    ],
  },
  {
    header: "Stories and Reels",
    para: "Tell a rich, visual story with immersive, full-screen vertical ads",
    childs: [
      "Instagram Stories",
      "Facebook Stories",
      "Messenger Stories",
      "Instagram Reels",
      "Facebook Reels",
    ],
  },
  {
    header: "In-stream ads for videos and reels",
    para: "Reach people before, during or after they watch a video or reel",
    childs: ["Facebook in-stream videos", "Ads on Facebook Reels"],
  },
];

const Placements = ({ adsetData, setAdsetData }) => {
  const [expandedSections, setExpandedSections] = useState({});
  const [selectedItems, setSelectedItems] = useState(
    placements.map(() => ({
      header: false,
      childs: [],
    }))
  );

  const toggleSection = (index) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // const handleHeaderChange = (index) => {
  //   setSelectedItems((prev) =>
  //     prev.map((section, idx) =>
  //       idx === index
  //         ? {
  //             header: !section.header,
  //             childs: !section.header
  //               ? placements[idx].childs.map(() => true)
  //               : [],
  //           }
  //         : section
  //     )
  //   );
  // };

  // Helper to map positions to their respective fields in `adsetData`
  const getPositionField = (childName) => {
    if (childName?.includes("Facebook")) return "facebook_positions";
    if (childName?.includes("Instagram")) return "instagram_positions";
    if (childName?.includes("Messenger")) return "messenger_positions";
    if (childName?.includes("Audience Network"))
      return "audience_network_positions";
    return null; // No match
  };

  // Handle changes to a section header (select/deselect all)
  const handleHeaderChange = (index) => {
    const allChildPositions = placements[index].childs;
    const selected = !selectedItems[index].header;

    // Update state
    setSelectedItems((prev) =>
      prev.map((section, idx) =>
        idx === index
          ? {
              header: selected,
              childs: selected ? placements[idx].childs.map(() => true) : [],
            }
          : section
      )
    );

    // Update adsetData
    setAdsetData((prevData) => {
      const updatedTargeting = { ...prevData.targeting };

      allChildPositions?.forEach((child) => {
        const field = getPositionField(child);
        if (field) {
          if (selected) {
            // Add all child positions
            updatedTargeting[field] = [
              ...(updatedTargeting[field] || []),
              child,
            ];
          } else {
            // Remove all child positions
            updatedTargeting[field] = updatedTargeting[field]?.filter(
              (item) => item !== child
            );
          }
        }
      });

      return {
        ...prevData,
        targeting: updatedTargeting,
      };
    });
  };

  // const handleChildChange = (sectionIndex, childIndex) => {
  //   setSelectedItems((prev) =>
  //     prev.map((section, idx) => {
  //       if (idx === sectionIndex) {
  //         const updatedChilds = section.childs.map((checked, cIdx) =>
  //           cIdx === childIndex ? !checked : checked
  //         );
  //         const allChecked = updatedChilds.every((checked) => checked);
  //         return {
  //           header: allChecked,
  //           childs: updatedChilds,
  //         };
  //       }
  //       return section;
  //     })
  //   );
  // };

  const mapPlacementToAPIValue = (platform, placement) => {
    const placementMap = {
      "Facebook Feed": "feed",
      "Facebook Right Column": "right_hand_column",
      "Facebook Marketplace": "marketplace",
      "Facebook Video Feeds": "video_feeds",
      "Instagram Feed": "stream",
      "Instagram Stories": "story",
      "Messenger Inbox": "inbox",
      "Audience Network": "classic",
    };

    // Map only valid placements
    return placementMap[placement] || null;
  };

  // Handle changes to individual child items
  const handleChildChange = (sectionIndex, childIndex) => {
    const childName = placements[sectionIndex].childs[childIndex];
    const isChecked = !selectedItems[sectionIndex].childs[childIndex];
    const platformField = getPositionField(childName);
    const apiValue = mapPlacementToAPIValue(platformField, childName);

    if (!apiValue) {
      console.error(`Invalid placement: ${childName}`);
      return;
    }

    setSelectedItems((prev) =>
      prev.map((section, idx) => {
        if (idx === sectionIndex) {
          const updatedChilds = section.childs.map((checked, cIdx) =>
            cIdx === childIndex ? isChecked : checked
          );
          const allChecked = updatedChilds.every((checked) => checked);
          return { header: allChecked, childs: updatedChilds };
        }
        return section;
      })
    );

    setAdsetData((prevData) => {
      const updatedTargeting = { ...prevData.targeting };

      if (isChecked) {
        updatedTargeting[platformField] = [
          ...(updatedTargeting[platformField] || []),
          apiValue,
        ];
      } else {
        updatedTargeting[platformField] = updatedTargeting[
          platformField
        ]?.filter((item) => item !== apiValue);
      }

      return { ...prevData, targeting: updatedTargeting };
    });
  };

  return (
    <div>
      {placements?.map((placement, index) => (
        <div key={index} className="mb-4 border rounded p-2">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection(index)}
          >
            <div className="flex items-start">
              <span className="text-lg">
                {expandedSections[index] ? (
                  <RiArrowDownSFill />
                ) : (
                  <RiArrowRightSFill />
                )}
              </span>
              <div>
                <h3 className="ml-2 font-semibold">{placement.header}</h3>
                <p>{placement?.para}</p>
              </div>
            </div>
            <input
              type="checkbox"
              checked={selectedItems[index].header}
              onChange={() => handleHeaderChange(index)}
            />
          </div>
          {expandedSections[index] && (
            <ul className="mt-2 pl-6">
              {/* {placement.childs.map((child, childIndex) => (
                <li
                  key={childIndex}
                  className="flex justify-between items-center py-2"
                >
                  <span>{child}</span>
                  <input
                    type="checkbox"
                    checked={selectedItems[index].childs[childIndex] || false}
                    onChange={() => handleChildChange(index, childIndex)}
                  />
                </li>
              ))} */}
              {placement.childs.map((child, childIndex) => (
                <li
                  key={childIndex}
                  className="flex justify-between items-center py-2"
                >
                  <span>{child}</span>
                  <input
                    type="checkbox"
                    checked={selectedItems[index].childs[childIndex] || false} // Bind checkbox state to `selectedItems`
                    onChange={() => handleChildChange(index, childIndex)} // Handle toggle
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default Placements;
