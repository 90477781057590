import React, { useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  FormLabel,
  Menu,
  Radio,
  RadioGroup,
} from "@mui/material";
import { selectStyles } from "../_elements/SelectStyles";
import { useStateContext } from "../../context/ContextProvider";
import Select from "react-select";
import { FormControl } from "@mui/base";
import { IoSearch } from "react-icons/io5";
import DetailedTargeting from "./DetailTargeting";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import axios from "axios";

const locationInclude = [
  {
    label: "Include",
    value: 1,
  },
  {
    label: "Exlude",
    value: 2,
  },
];

const Audiences = ({ adsetData, setAdsetData, fb_token }) => {
  const { currentMode, primaryColor, themeBgImg, fbCredentials } =
    useStateContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElTargeting, setAnchorElTargeting] = useState(null);

  const [newLocation, setNewLocation] = useState({ name: "", radius: "2 km" });

  const [interestQuery, setInterestQuery] = useState(null);
  const [demoQuery, setDemoQuery] = useState(null);
  const [behaviorQuery, setBehaviorQuery] = useState(null);

  const [interestResults, setInterestResults] = useState([]);
  const [demographicsResults, setDemographicsResults] = useState([]);
  const [behaviorResults, setBehaviorResults] = useState([]);

  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedDemographics, setSelectedDemographics] = useState([]);
  const [selectedBehaviors, setSelectedBehaviors] = useState([]);
  const [customAudienceOptions, setCustomAudienceOptions] = useState([]);
  const [selectedCustomAudience, setSelectedCustomAudience] = useState(null);
  const [loadingCustomAudience, setLoadingCustomAudience] = useState(false);

  const [loadingData, setLoadingData] = useState({
    behaviors: false,
    demographics: false,
    interests: false,
  });

  const fetchTargetingOptions = async (type, query, class_name, setResults) => {
    // if (!query?.trim()) {
    //   setResults([]);
    //   return;
    // }

    setLoadingData({
      ...loadingData,
      [class_name]: true,
    });

    try {
      const response = await axios.get(
        `http://localhost:5000/api/targeting-search`,
        {
          params: {
            type,
            query,
            class_name,
          },
        }
      );

      console.log("behavior targeting res::: ", response);

      if (response?.data?.data) {
        setResults(
          response?.data?.data?.data?.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      } else {
        setResults([]);
        console.error("No targeting options found.");
        toast.error(` No targeting options found.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      setLoadingData({
        ...loadingData,
        [type]: false,
      });
    } catch (error) {
      setLoadingData({
        ...loadingData,
        [type]: false,
      });

      console.error("Error fetching targeting options:", error);
      toast.error(
        ` ${error?.response?.data?.error_msg || `Error fetching ${type}`}.`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  // const handleSelectChange = (selectedOptions, type) => {
  //   if (type === "interests") {
  //     setSelectedInterests(selectedOptions);
  //   } else if (type === "demographics") {
  //     setSelectedDemographics(selectedOptions);
  //   } else if (type === "behaviors") {
  //     setSelectedBehaviors(selectedOptions);
  //   }

  //   // Update ad set targeting with the latest selectedOptions
  //   setAdsetData((prevData) => {
  //     const updatedFlexibleSpec = [...(prevData?.targeting[type] || [])];

  //     if (type === "interests") {
  //       updatedFlexibleSpec[0] = {
  //         interests: selectedOptions?.map((option) => ({
  //           id: option?.value,
  //           name: option?.label,
  //         })),
  //       };
  //     } else if (type === "demographics") {
  //       updatedFlexibleSpec[1] = {
  //         demographics: selectedOptions?.map((option) => ({
  //           id: option?.value,
  //           name: option?.label,
  //         })),
  //       };
  //     } else if (type === "behaviors") {
  //       updatedFlexibleSpec[2] = selectedOptions?.map((option) => ({
  //         id: option?.value,
  //         name: option?.label,
  //       }));
  //     }

  //     return {
  //       ...prevData,
  //       targeting: {
  //         ...prevData.targeting,
  //         // flexible_spec: updatedFlexibleSpec,
  //         [type]: { updatedFlexibleSpec },
  //       },
  //     };
  //   });
  // };

  const handleCustomAudienceChange = (selectedOption) => {
    setSelectedCustomAudience(selectedOption);

    // Update `adsetData` with relevant targeting from the selected audience
    setAdsetData((prevData) => ({
      ...prevData,
      targeting: {
        ...prevData.targeting,
        ...selectedOption.targeting, // Merge audience targeting into adsetData
      },
    }));
  };

  const handleSelectChange = (selectedOptions, type) => {
    // Update the local state for the selected options
    if (type === "interests") {
      setSelectedInterests(selectedOptions);
    } else if (type === "demographics") {
      setSelectedDemographics(selectedOptions);
    } else if (type === "behaviors") {
      setSelectedBehaviors(selectedOptions);
    }

    // Update the ad set targeting
    // setAdsetData((prevData) => ({
    //   ...prevData,
    //   targeting: {
    //     ...prevData.targeting,
    //     [type]: selectedOptions?.map((option) => ({
    //       id: option?.value,
    //       name: option?.label,
    //     })),
    //   },
    // }));

    let key = type === "interests" ? "interests" : "behaviors";
    // Update the ad set targeting
    setAdsetData((prevData) => ({
      ...prevData,
      targeting: {
        ...prevData.targeting,
        [key]: selectedOptions?.map((option) => ({
          id: option?.value,
          name: option?.label,
        })),
      },
    }));
    // setAdsetData((prevData) => ({
    //   ...prevData,
    //   targeting: {
    //     ...prevData.targeting,
    //     flexible_spec: [
    //       ...(prevData?.targeting?.flexible_spec || []),
    //       {
    //         interests: selectedOptions?.map((item) => ({
    //           id: item.id,
    //           name: item.name,
    //         })),
    //       },
    //     ],
    //   },
    // }));
  };

  const fetchCustomAudiences = () => {
    setLoadingCustomAudience(true);

    if (window.FB) {
      window.FB.api(
        `/${fbCredentials?.currentAdAccount}/saved_audiences`,
        "GET",
        {
          fields: "account,description,id,name,sentence_lines,targeting",
          access_token: fb_token,
        },
        (response) => {
          if (!response || response.error) {
            console.error("Error fetching custom audiences:", response?.error);
            toast.error(
              `${
                response?.error?.message || "Failed to load custom audiences."
              }`,
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
            setLoadingCustomAudience(false);
            return;
          }

          // Map the fetched audiences into options
          const audienceData = response?.data?.map((audience) => ({
            value: audience?.id,
            label: audience?.name,
            targeting: audience?.targeting, // Include targeting for later use
          }));

          setCustomAudienceOptions(audienceData);
          setLoadingCustomAudience(false);
        }
      );
    } else {
      console.error("Facebook SDK is not initialized.");
      toast.error("Facebook SDK is not initialized.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoadingCustomAudience(false);
    }
  };

  useEffect(() => {
    // if (behaviorQuery) {
    //   fetchTargetingOptions(
    //     "adTargetingCategory",
    //     behaviorQuery,
    //     "behaviors",
    //     setBehaviorResults
    //   );
    // } else
    // if (demoQuery) {
    //   fetchTargetingOptions("addemographic", demoQuery, setDemographicsResults);
    // } else
    if (interestQuery) {
      fetchTargetingOptions(
        "adinterest",
        interestQuery,
        "",
        setInterestResults
      );
    }
  }, [behaviorQuery, demoQuery, interestQuery]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  return (
    <>
      <Box>
        <label htmlFor="" className="text-[16px] font-semibold mb-3">
          Advantage+ audience
        </label>
        <p>
          Our ad technology automatically finds your audience. If you share an
          audience suggestion, we'll prioritise audiences matching this profile
          before searching more widely..
        </p>

        <div className="flex items-center justify-between mb-3">
          {/* <label
            htmlFor=""
            className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
          >
            Custom Audiences
          </label> */}
          {/* <button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            className="px-3 py-2 border border-gray-200 rounded-lg flex gap-3 items-center"
          >
            Create New
          </button> */}
        </div>
        <div className="w-full ">
          <Select
            id="custom-audience"
            options={customAudienceOptions}
            value={selectedCustomAudience}
            onChange={(selectedOption) =>
              handleCustomAudienceChange(selectedOption)
            }
            onMenuOpen={() => {
              if (customAudienceOptions.length === 0) {
                fetchCustomAudiences();
              }
            }}
            placeholder="Search existing audiences"
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />
        </div>

        {/* <label
          htmlFor=""
          className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
        >
          Detailed targeting
        </label>
        <p className="text-gray-500 font-medium mb-3">
          Include people who match
        </p>

        <div
          // className="bg-[#F2F2F2] rounded-lg py-3 px-4"
          className={` ${
            currentMode === "dark" ? "bg-gray-700" : "bg-[#F2F2F2]"
          } rounded-lg py-3 px-4`}
        >
          <div
            //  className="bg-white flex justify-between items-center px-3 rounded-lg py-3 group"
            className={` ${
              currentMode === "dark" ? "bg-black" : "bg-white"
            } flex justify-between items-center px-3 rounded-lg py-3 my-3 group`}
          >
            <div className="flex gap-4 items-center">At high school</div>
            <span className="group-hover:block hidden cursor-pointer">
              <RxCross2 />
            </span>
          </div>
          <div
            // className="bg-white flex justify-between items-center px-3 rounded-lg py-3 group"
            className={` ${
              currentMode === "dark" ? "bg-black" : "bg-white"
            } flex justify-between items-center px-3 rounded-lg py-3 my-3 group`}
          >
            <div className="flex gap-4 items-center">At high University</div>
            <span className="group-hover:block hidden cursor-pointer">
              <RxCross2 />
            </span>
          </div>
        </div> */}
        {/* <div className="flex items-center gap-4 border-b py-5 px-7 flex-1 mb-5">
          <IoSearch size={16} />

          <input
            type="text"
            className={`focus:outline-none border-none flex-1 ${
              currentMode === "dark" ? "bg-dark" : "bg-white"
            }`}
            placeholder="Add demographics,interests, or behaviours"
            // onChange={(e) => setSearchTerm(e?.target?.value)}
            // value={searchTerm}
          />
          <button
            onClick={(event) => {
              setAnchorElTargeting(event.currentTarget?.parentElement);
              console.log(
                event.currentTarget?.parentElement,
                "parent of browse"
              );
            }}
          >
            Browse
          </button>
        </div> */}
        <div className="flex gap-4 border-b py-5 px-7">
          {/* Interests Search */}
          <div className="flex-1">
            <div className="flex items-center gap-2 mb-2">
              {/* <IoSearch size={16} /> */}
              <span>Interests</span>
            </div>
            <Select
              id="interests"
              options={interestResults}
              value={selectedInterests}
              onChange={(options) => handleSelectChange(options, "interests")}
              onInputChange={(query) => {
                if (query.trim()) {
                  setInterestQuery(query);
                }
              }}
              isLoading={loadingData?.interests}
              placeholder="Search Interests"
              isMulti={true}
              menuPortalTarget={document.body}
              styles={selectStyles(currentMode, primaryColor)}
            />
          </div>

          {/* Demographics Search */}
          <div className="flex-1">
            <div className="flex items-center gap-2 mb-2">
              {/* <IoSearch size={16} /> */}
              <span>Demographics</span>
            </div>
            <Select
              options={demographicsResults}
              value={selectedDemographics}
              onChange={(options) =>
                handleSelectChange(options, "demographics")
              }
              // onInputChange={(query) => {
              //   setDemoQuery(query);

              // }}
              onMenuOpen={() => {
                if (demographicsResults?.length > 0) {
                  return;
                }
                fetchTargetingOptions(
                  "adTargetingCategory",
                  demoQuery,
                  "demographics",
                  setDemographicsResults
                );
              }}
              placeholder="Search Demographics"
              isLoading={loadingData?.demographics}
              isMulti={true}
              menuPortalTarget={document.body}
              styles={selectStyles(currentMode, primaryColor)}
            />
          </div>

          {/* Behaviors Search */}
          <div className="flex-1">
            <div className="flex items-center gap-2 mb-2">
              {/* <IoSearch size={16} /> */}
              <span>Behaviors</span>
            </div>
            <Select
              options={behaviorResults}
              value={selectedBehaviors}
              onChange={(options) => handleSelectChange(options, "behaviors")}
              // onInputChange={(query) => {
              //   setBehaviorQuery(query);

              // }}
              onMenuOpen={() => {
                if (behaviorResults?.length > 0) {
                  return;
                }
                fetchTargetingOptions(
                  "adTargetingCategory",
                  behaviorQuery,
                  "behaviors",
                  setBehaviorResults
                );
              }}
              isLoading={loadingData?.behaviors}
              placeholder="Search Behaviors"
              isMulti={true}
              menuPortalTarget={document.body}
              // styles={{
              //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              // }}
              styles={selectStyles(currentMode, primaryColor)}
            />
          </div>
        </div>
        <button
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 ${
            currentMode == "dark" ? "bg-black text-white" : "bg-white"
          } `}
        >
          Narrow audience
        </button>
        <div className="flex items-center justify-between gap-3 w-full pt-3 border-t-[2px]">
          <button
            // onClick={() => setCreateCampaignModal(false)}
            className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium ${
              currentMode == "dark" ? "bg-black text-white" : "bg-white"
            } `}
          >
            Save Audience
          </button>
          <button className="text-[#0A78BE]">
            Switch to original audience options
          </button>
        </div>
        {/* <div className="bg-[#F2F2F2] rounded-lg py-3 px-4">
        <label htmlFor="" className="text-[14px] mb-3">
          Pakistan
        </label>
        <div className="bg-white flex justify-between items-center px-3 rounded-lg py-3 group">
          <div className="flex gap-4 items-center">
            <MdLocationOn color="green" size={16} />
            Charsadda Sherpao
          </div>
          <span className="group-hover:block hidden cursor-pointer">
            <RxCross2 />
          </span>
        </div>
      </div>
      <div className="flex items-center gap-3 mt-3">
        <div className="w-[150px]">
          <Select
            // isLoading={adSetLoading}
            id="include-exlude"
            options={locationInclude}
            // value={adDetails?.publicProfile}
            // onChange={(e) => {
            //   setAdDetails((pre) => ({
            //     ...pre,
            //     publicProfile: e,
            //   }));
            // }}
            placeholder={""}
            className={`w-full mb-0`}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />
        </div>

        <div className="flex items-center gap-4 border-b py-5 px-7 flex-1 mb-5">
          <IoSearch size={16} />

          <input
            type="text"
            className={`focus:outline-none border-none flex-1 ${
              currentMode === "dark" ? "bg-dark" : "bg-white"
            }`}
            placeholder="Search Locations"
            // onChange={(e) => setSearchTerm(e?.target?.value)}
            // value={searchTerm}
          />
        </div>
      </div> */}
        {/* Map */}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiList-root": {
            padding: "0px !important", // Modify border radius
          },
          padding: "0px",
        }}
      >
        <div className={` ${getSummaryBgClass()} py-4`}>
          <div className="">
            <ul className="px-1">
              <li
                className={`w-full hover:bg-gray-200 py-3 ${
                  currentMode === "dark" ? "hover:text-black" : ""
                } px-2 rounded-lg cursor-pointer flex flex-col justify-between gap-4`}
              >
                <span className="text-[14px]">Upcoming entries</span>
                <p>
                  Reach people who have already interacted with your business.
                </p>
              </li>
              <li
                className={`w-full hover:bg-gray-200 py-3 ${
                  currentMode === "dark" ? "hover:text-black" : ""
                } px-2 rounded-lg cursor-pointer flex flex-col justify-between gap-4`}
              >
                <span className="text-[14px]">Lookalike Audience</span>
                <p>
                  Reach new people on Meta technologies who are similar to your
                  most valuable audiences.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </Menu>
      <DetailedTargeting
        anchorEl={anchorElTargeting}
        setAnchorEl={setAnchorElTargeting}
      />
    </>
  );
};

export default Audiences;
