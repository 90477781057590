import React, { useState, useEffect } from "react";
import { Backdrop, Checkbox, Modal } from "@mui/material";
import Select from "react-select";
import { useStateContext } from "../../context/ContextProvider";
import { selectStyles } from "../_elements/SelectStyles.jsx";
import axios from "../../axoisConfig.js";
import { CiFolderOn } from "react-icons/ci";
import { GoPlusCircle } from "react-icons/go";
import { IoCloseOutline } from "react-icons/io5";
import { TiArrowSortedDown } from "react-icons/ti";
import Menu from "@mui/material/Menu";
import { IoIosImages } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { RiPagesLine } from "react-icons/ri";
import { FaMinus } from "react-icons/fa6";
import { IoIosCrop } from "react-icons/io";
import { LiaExchangeAltSolid } from "react-icons/lia";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { width } from "@mui/system";

const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const token = localStorage?.getItem("auth-token");

const imagesSources = [
  {
    label: "Acount Images",
    icon: <IoIosImages size={16} />,
  },
  {
    label: "Instagram Images",
    icon: <FaInstagram size={16} />,
  },
  {
    label: "Pages Images",
    icon: <RiPagesLine size={16} />,
  },
];

const AdCreativeModal = ({ setAdCreativeModal, adCreativeModal }) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    snapchatCredentials,
    themeBgImg,
  } = useStateContext();
  const tabs = [
    {
      name: "Text",
      Component: <Text />,
    },
    {
      name: "Add media",
      Component: <AddMedia />,
    },
    {
      name: "Crop",
      Component: <Crop />,
    },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  return (
    <Modal
      //   keepMounted
      open={adCreativeModal}
      onClose={() => setAdCreativeModal(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        zIndex: 1400,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[70%] h-[600px] ${
          // currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          currentMode === "dark"
            ? "bg-dark-neu text-white"
            : "bg-white text-black"
        } absolute top-1/2 left-1/2  rounded-xl flex flex-col items-start`}
      >
        <div className="w-full flex flex-1 overflow-y-scroll">
          <div className="w-[20%] bg-gradient-to-br from-[#F3EEF0] to-[#E7F0F9] h-full rounded-l-xl px-4 pt-4">
            {" "}
            <label htmlFor="" className="text-[14px] font-semibold mb-3 ">
              Set up your media
            </label>
            <ul className="mt-4">
              {tabs?.map((tab) => {
                return (
                  <li
                    className={` py-3 px-2 cursor-pointer  rounded-lg  ${
                      currentTab?.name === tab?.name
                        ? "text-[#0A78BE] bg-[#E1EDF7]"
                        : " text-black"
                    } flex gap-3 items-center text-[14px] font-semibold`}
                    onClick={() => setCurrentTab(tab)}
                  >
                    <div
                      className={`flex rounded-full w-[20px] h-[20px] border ${
                        currentTab?.name === tab?.name
                          ? "border-[#0A78BE]"
                          : "border-gray-700"
                      } `}
                    >
                      {currentTab?.name === tab?.name && (
                        <>
                          <div className="w-1/2 bg-[#F3EEF0] rounded-l-full"></div>
                          <div className="w-1/2 bg-[#0A78BE] rounded-r-full"></div>
                        </>
                      )}
                    </div>{" "}
                    {tab?.name}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="px-5 pt-5 flex flex-col flex-1 pb-5">
            <div className="flex-1">{currentTab?.Component}</div>

            <div className="flex items-center justify-end gap-3 w-full pt-3 border-t-[2px]">
              <button
                onClick={() => setAdCreativeModal(false)}
                className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium ${
                  currentMode == "dark" ? "bg-dark text-white" : "bg-white"
                } `}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setAdCreativeModal(false);
                  // setIsSingleAdSet(selectedCampaign?.value);
                  //   setIsSingleAdSet(1);
                  //   setAdSetDetails(null);
                }}
                className="p-3 px-7 rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
                // disabled={!selectedCampaign}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AdCreativeModal;

const Text = () => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    snapchatCredentials,
    themeBgImg,
  } = useStateContext();

  const [checked, setChecked] = useState(false);

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <div className="flex flex-col">
      <label htmlFor="" className="text-[14px] font-semibold mb-3">
        Primary text (1 of 5)
      </label>
      <input
        type="text"
        className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
        //   value={campData?.name}
        placeholder="Tell people what your ad is about"
        //   onChange={(e) =>
        //     setCampData((pre) => ({
        //       ...pre,
        //       name: e?.target?.value,
        //     }))
        //   }
      />
      <button
        // onClick={(event) => setAnchorEl(event.currentTarget)}
        className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
          currentMode == "dark" ? "bg-dark text-white" : "bg-white"
        } `}
      >
        <GoPlusCircle size={16} /> Add text option
      </button>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} />}
        label="Add a destination"
      />
      <p className="pl-9">
        If you add a destination, you can send people immediately after they've
        tapped or clicked your ad to a website, a full-screen experience or a
        call. If you don't, they'll be sent to your Facebook Page or Instagram
        account.
      </p>
      {checked && (
        <>
          {" "}
          <label htmlFor="" className="text-[14px] font-semibold mb-3">
            Headline
          </label>
          <input
            type="text"
            className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
            //   value={campData?.name}
            placeholder="Write a short headline"
            //   onChange={(e) =>
            //     setCampData((pre) => ({
            //       ...pre,
            //       name: e?.target?.value,
            //     }))
            //   }
          />
          <div className="flex gap-3 items-center my-2 w-full">
            <input
              type="text"
              className={`p-2 flex-1 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
              //   value={campData?.name}
              placeholder="Write a short headline"
              //   onChange={(e) =>
              //     setCampData((pre) => ({
              //       ...pre,
              //       name: e?.target?.value,
              //     }))
              //   }
            />
            <div className="cursor-pointer">
              <IoCloseOutline size={16} />
            </div>
          </div>
          <button
            // onClick={(event) => setAnchorEl(event.currentTarget)}
            className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
              currentMode == "dark" ? "bg-dark text-white" : "bg-white"
            } `}
          >
            <GoPlusCircle size={16} /> Add headline option
          </button>
        </>
      )}
    </div>
  );
};
const AddMedia = () => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    snapchatCredentials,
    themeBgImg,
  } = useStateContext();

  const [checked, setChecked] = useState(false);
  const [imagesSource, setImagesSource] = useState(imagesSources[0]);
  const [anchorEl, setAnchorEl] = useState(null);

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <>
      <div className="flex flex-col">
        <label htmlFor="" className="text-[14px] font-semibold mb-3">
          Select Media
        </label>
        <div className="flex gap-3 w-full items-center">
          <div>
            <label
              htmlFor="upload-img"
              // onClick={(event) => setAnchorEl(event.currentTarget)}
              className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
                currentMode == "dark" ? "bg-dark text-white" : "bg-white"
              } `}
            >
              <GoPlusCircle size={16} /> Upload
            </label>
            <input type="file" name="" className="hidden" id="upload-img" />
          </div>
          <div
            className={`   p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium mb-4 flex items-center w-fit gap-3 mt-3  ${
              currentMode == "dark" ? "bg-dark text-white" : "bg-white"
            } `}
            onClick={(e) => setAnchorEl(e?.currentTarget)}
          >
            {imagesSource?.icon} {imagesSource?.label} <TiArrowSortedDown />
          </div>
          <input
            type="text"
            className={`p-3 px-5 flex-1 ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
            //   value={campData?.name}
            placeholder="Tell people what your ad is about"
            //   onChange={(e) =>
            //     setCampData((pre) => ({
            //       ...pre,
            //       name: e?.target?.value,
            //     }))
            //   }
          />
        </div>
        <div className="flex gap-8 select-none">
          {[1, 2, 3].map(() => {
            return (
              <div className="w-[100px] h-[150px]  rounded-lg flex flex-col relative">
                <div className="w-full h-[60%] relative">
                  <img
                    src="/assets/female_character.png"
                    className="w-full object-contain h-full border-[2px] rounded-lg border-transparent hover:border-[#0A78BE] "
                    alt=""
                  />
                  <span className="p-[2px] text-white rounded-full bg-red-600 absolute bottom-2 right-2">
                    <FaMinus size={14} />
                  </span>
                </div>

                <div className="overflow-y-hidden truncate text-ellipsis break-words w-full">
                  female_character.png
                </div>
                <span>100 x 100</span>
              </div>
            );
          })}
        </div>
      </div>
      <ImageSources anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};
const Crop = () => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    snapchatCredentials,
    themeBgImg,
  } = useStateContext();

  const [checked, setChecked] = useState(false);
  const [imagesSource, setImagesSource] = useState(imagesSources[0]);
  const [anchorEl, setAnchorEl] = useState(null);

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const ImageCards = [
    {
      label: "Square",
      height: "100px",
      width: "100px",
      options: [
        {
          label: "Original",
          value: "original",
        },
        {
          label: "1:1",
          value: "1:1",
        },
      ],
    },
    {
      label: "Vertical",
      height: "300px",
      width: "100px",
      options: [
        {
          label: "Original",
          value: "original",
        },
        {
          label: "9:16",
          value: "9:16",
        },
      ],
    },
    {
      label: "Horizontal",
      height: "200px",
      width: "100px",
      options: [
        {
          label: "Original",
          value: "original",
        },
        {
          label: "1.91:1",
          value: "1:1",
        },
      ],
    },
  ];
  return (
    <>
      <div className="flex flex-col">
        <label htmlFor="" className="text-[14px] font-semibold mb-3">
          Select media crop for placements
        </label>
        <p>
          We evaluated your image and cropped it to fill the placement when we
          think it may perform better. You can edit these crops or upload new
          media for each placement by hovering over the image.
        </p>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleChange} />}
          label="Enable flexible media to deliver the media that you select below to additional placements when it's likely to improve performance"
        />
        <div className="flex gap-3 w-full items-start overflow-y-auto h-full">
          {ImageCards?.map((card) => {
            return <ImageCard card={card} />;
          })}
        </div>
      </div>
    </>
  );
};

function ImageCard({ card }) {
  const { height, width, options, label } = card;
  const [value, setValue] = React.useState("option1");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div className="border rounded-lg px-3 py-3 flex flex-col w-full ">
      <label htmlFor="" className="text-[14px] font-semibold mb-3">
        {label}
      </label>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">
          Select an Option
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          {options?.map((option) => {
            return (
              <FormControlLabel
                value={option?.value}
                control={<Radio />}
                label={option?.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      <div className="w-full flex flex-col h-fit"></div>
      <div className="w-full bg-gray-500 " style={{ height: height }}></div>
      {/* <div style={{ height: height, width: width, backgroundColor: "gray" }}>
        <img src="/assets/female_character.png" className="w-full" alt="" />
      </div> */}
      <div className="w-full flex gap-3">
        <button className="bg-[#F2F2F2] p-2 rounded-lg flex items-center gap-2">
          <IoIosCrop /> Crop
        </button>
        <button className="bg-[#F2F2F2] p-2 rounded-lg flex items-center gap-2">
          <LiaExchangeAltSolid />
          Replace
        </button>
      </div>
    </div>
  );
}
function ImageSources({ anchorEl, setAnchorEl }) {
  const open = Boolean(anchorEl);
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    setSnapchatCredentials,
  } = useStateContext();

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? "bg-dark text-white"
      : "bg-white text-black";
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log("This is called", anchorEl);

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiList-root": {
            padding: "0px !important", // Modify border radius
          },
          padding: "0px",
          zIndex: 9999999,
        }}
      >
        <div className={` ${getSummaryBgClass()} py-4`}>
          <div className="">
            <ul className="px-1">
              {imagesSources?.map((source) => {
                return (
                  <li
                    className={` flex items-center gap-2 w-full hover:bg-gray-200 py-3 ${
                      currentMode === "dark" ? "hover:text-black" : ""
                    } px-2 rounded-lg cursor-pointer flex  gap-4`}
                  >
                    {source?.icon} <span>{source?.label}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Menu>
    </div>
  );
}
