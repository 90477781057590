import React, { useEffect, useState } from "react";
import { BsMegaphone } from "react-icons/bs";
import { MdAdd, MdFilterList } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { PiGridFourLight } from "react-icons/pi";
import { LuRectangleVertical } from "react-icons/lu";
import CompaignsTable from "./CompaignsTable";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import CreateCampaignModal from "./CreateCampaign";
import SingleCampaign from "./SingleCampaign";
import AdSetsTable from "./adSetTable";
import SingleAdSet from "./SingleAdSet";
import CreateAdSetModal from "./createAdSet";
import AdsTable from "./AdsTable";
import SingleAd from "./singleAd";
import CreateAdModal from "./createAd";

const token = localStorage?.getItem("auth-token");
const oldObjectives = {
  awareness: [
    {
      label: "Awareness",
      banner:
        "https://release-manager.sc-cdn.net/218e1a0c4b913ae4d17c6404d31ae915.svg",
      content: {
        para: "Reach Snapchatters and create interest in your brand",
      },
      identifier: "BRAND_AWARENESS",
    },
    {
      label: "Promote Places",
      banner: "/assets/promote_places.png",
      content: {
        para: "Promote your places to Snapchatters",
      },
      identifier: "PROMOTE_PLACES",
    },
  ],
  consideration: [
    {
      label: "App Installs",
      banner: "/assets/app_installs.png",
      content: {
        para: "Send Snapchatters to the app store to download your app",
      },
      identifier: "APP_INSTALL",
    },
    {
      label: "Drive Traffic to Website",

      banner: "/assets/website_traffic.png",
      content: {
        para: "Send Snapchatters directly to your website",
      },
      identifier: "WEB_VIEW",
    },
    {
      label: "Drive Traffic to App",
      banner: "/assets/app_traffic.png",
      content: {
        para: "Send Snapchatters to your app or a third-party app",
      },
      identifier: "APP_REENGAGEMENT",
    },
    {
      label: "Engagement",

      banner: "/assets/engagement.png",
      content: {
        para: "Get more Snapchatters to engage with your ad",
      },
      identifier: "ENGAGEMENT",
    },
    {
      label: "Video Views",

      banner: "/assets/video_views.png",
      content: {
        para: "Promote your brand or product to Snapchatters through video. Learn more.",
      },
      identifier: "VIDEO_VIEW",
    },
    {
      label: "Lead Generation",

      banner: "/assets/leads.png",
      content: {
        para: "Generate leads for your business",
      },
      identifier: "LEAD_GENERATION",
    },
  ],
  conversions: [
    {
      label: "App Conversions",

      banner: "/assets/app_promotion.png",
      content: {
        para: "Drive specific actions within your app",
      },
      identifier: "APP_CONVERSION",
    },
    {
      label: "Website Conversions",

      banner: "/assets/app_promotion.png",
      content: {
        para: "Drive specific actions on your website",
      },
      identifier: "WEB_CONVERSION",
    },
  ],
};

const Compaigns = () => {
  const {
    currentMode,
    themeBgImg,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
  } = useStateContext();
  const [currentTable, setCurrentTable] = useState("campaigns");
  const [isCampaignModal, setIsCampaignModal] = useState(false);
  const [isSingleCampaign, setIsSingleCampaign] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [isSingleAdSet, setIsSingleAdSet] = useState(false);
  const [isSingleAd, setIsSingleAd] = useState(false);
  const [createAdSetModal, setCreateAdSetModal] = useState(false);
  const [adSetDetails, setAdSetDetails] = useState(null);
  const [adDetails, setAdDetails] = useState(null);
  const [compaigns, setComapaigns] = useState([]);
  const [adSets, setAdSets] = useState([]);
  const [ads, setAds] = useState([]);
  const [createAdModal, setCreateAdModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState({
    campaigns: [],
    adSets: [],
  });
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  async function getAllCompaigns() {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/all/campaigns`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setComapaigns(res?.data?.data?.campaigns?.map((item) => item?.campaign));
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  }

  async function getAllAdSets() {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/getall-adsquad-under-adaccount`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setAdSets(res?.data?.data?.adsquads?.map((item) => item?.adsquad));
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  }

  async function getAllAds() {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/ads-under-adaccount`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setAds(res?.data?.data?.ads?.map((item) => item?.ad));
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  }
  async function getAllAdSetsUnderCampaign(id) {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/getall-adsquad-under-campaign`,
        {
          access_token: snapchatCredentials?.access_token,
          campaign_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return res?.data?.data?.adsquads?.map((item) => item?.adsquad);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  }
  async function getAllAdsUnderCampaign(id) {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/ads-under-campaign`,
        {
          access_token: snapchatCredentials?.access_token,
          campaign_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return res?.data?.data?.ads?.map((item) => item?.ad);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  }
  async function getAllAdsUnderAdSet(id) {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/ads-under-adsquad`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_squad_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return res?.data?.data?.ads?.map((item) => item?.ad);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (snapchatCredentials?.access_token) {
      getAllCompaigns();
    }
  }, [snapchatCredentials]);

  useEffect(() => {
    if (currentTable === "adSets" && selectedRows?.campaigns?.length > 0) {
      fetchAdSetsOfDifferentCampaings();
    } else if (snapchatCredentials?.access_token && currentTable === "adSets") {
      getAllAdSets();
    } else if (currentTable === "ads" && selectedRows?.adSets?.length > 0) {
      fetchAdsOfDifferentAdSets();
    } else if (currentTable === "ads" && selectedRows?.campaigns?.length > 0) {
      fetchAdsOfDifferentCampaings();
    } else if (snapchatCredentials?.access_token && currentTable === "ads") {
      getAllAds();
    }
  }, [currentTable]);

  async function fetchAdSetsOfDifferentCampaings() {
    setLoading(true);
    try {
      const adSetsPromises = selectedRows?.campaigns?.map(async (id) => {
        try {
          return await getAllAdSetsUnderCampaign(id);
        } catch (error) {
          console.log(error);
          return [];
        }
      });

      const adSets = await Promise.all(adSetsPromises);
      setAdSets(adSets.flat()); // Flatten the array of arrays
    } catch (error) {
      console.log(error, "this is an error");
    } finally {
      setLoading(false);
    }
  }
  async function fetchAdsOfDifferentCampaings() {
    setLoading(true);
    try {
      const adsPromises = selectedRows?.campaigns?.map(async (id) => {
        try {
          return await getAllAdsUnderCampaign(id);
        } catch (error) {
          console.log(error);
          return [];
        }
      });

      const ads = await Promise.all(adsPromises);
      setAds(ads.flat()); // Flatten the array of arrays
    } catch (error) {
      console.log(error, "this is an error");
    } finally {
      setLoading(false);
    }
  }
  async function fetchAdsOfDifferentAdSets() {
    setLoading(true);
    try {
      const adsPromises = selectedRows?.adSets?.map(async (id) => {
        try {
          return await getAllAdsUnderAdSet(id);
        } catch (error) {
          console.log(error);
          return [];
        }
      });

      const ads = await Promise.all(adsPromises);
      setAds(ads.flat()); // Flatten the array of arrays
    } catch (error) {
      console.log(error, "this is an error");
    } finally {
      setLoading(false);
    }
  }
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  return (
    <>
      <div className={`px-6 ${getSummaryBgClass()}`}>
        <div className="flex gap-1 py-9">
          <div
            className={`flex items-center  gap-8 py-6  px-5  ${getSummaryBgClass()} flex-1 rounded-md shadow-md`}
          >
            <div>
              <FiSearch size={16} />
            </div>
            <input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e?.target?.value)}
              type="text"
              className={`focus:outline-none flex-1 ${getSummaryBgClass()}`}
              placeholder="Search name or ID"
            />
          </div>
        </div>
        <div className="shadow-lg">
          <div
            className={`flex  justify-between  border-b  border-b-gray-400 ${getSummaryBgClass()}`}
          >
            <div className="flex gap-8 items-center">
              <div
                onClick={() => setCurrentTable("campaigns")}
                className={`flex gap-3 px-6 items-center h-full py-7 cursor-pointer border-b-[3px]  ${
                  currentTable === "campaigns"
                    ? "border-b-[#F7D901]"
                    : "border-b-transparent"
                }`}
              >
                {/* <BsMegaphone /> */}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#444546"
                  xmlns="http://www.w3.org/2000/svg"
                  class="sds-icon sds-icon-megaphone"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.5 11.8007L8.75 9.13203V6.16447L12.5 3.49582V11.8007ZM5.75 12.1317C5.75 12.338 5.58125 12.5054 5.375 12.5054C5.16875 12.5054 5 12.338 5 12.1317V10.2635H5.75V12.1317ZM3.5 7.27422C3.5 6.86245 3.836 6.52691 4.25 6.52691H7.25V8.76884H4.25C3.836 8.76884 3.5 8.43404 3.5 8.02153V7.27422ZM13.1862 2.16561C12.6845 1.91003 12.0875 1.95188 11.6278 2.27995L7.76 5.03229H4.25C3.0095 5.03229 2 6.03817 2 7.27422V8.02153C2 8.99452 2.62925 9.81657 3.5 10.126V12.1317C3.5 13.1623 4.34075 14 5.375 14C6.40925 14 7.25 13.1623 7.25 12.1317V10.2635H7.76L11.6278 13.0165C11.8888 13.2019 12.1932 13.2968 12.4992 13.296C12.734 13.296 12.9688 13.2415 13.1862 13.1301C13.688 12.8731 14 12.3634 14 11.8007V3.49582C14 2.9331 13.688 2.42344 13.1862 2.16561Z"
                  ></path>
                </svg>
                <span className="font-medium text-[14px]">Campaigns</span>
                {selectedRows?.campaigns?.length > 0 && (
                  <div className="rounded-[20px] p-3 border">
                    {selectedRows?.campaigns?.length} Selected
                  </div>
                )}
              </div>
              <div
                onClick={() => setCurrentTable("adSets")}
                // className="flex gap-3 items-center h-full py-7 cursor-pointer hover:border-b-[3px] border-b-[#F7D901]"
                className={`flex gap-3 px-6 items-center h-full py-7 cursor-pointer border-b-[3px]   ${
                  currentTable === "adSets"
                    ? "border-b-[#F7D901]"
                    : "border-b-transparent"
                }`}
              >
                {/* <PiGridFourLight /> */}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#444546"
                  xmlns="http://www.w3.org/2000/svg"
                  class="sds-icon sds-icon-grid-rectangles-four"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.75011 1.91669C7.75011 1.64054 7.52625 1.41669 7.25011 1.41669H3.69678C3.42063 1.41669 3.19678 1.64054 3.19678 1.91669V7.25002C3.19678 7.52616 3.42063 7.75002 3.69678 7.75002H7.25011C7.52625 7.75002 7.75011 7.52616 7.75011 7.25002V1.91669ZM4.19678 2.41669H6.75011V6.75002H4.19678V2.41669ZM7.75011 8.75C7.75011 8.47386 7.52625 8.25 7.25011 8.25H3.69678C3.42063 8.25 3.19678 8.47386 3.19678 8.75V14.0833C3.19678 14.3595 3.42063 14.5833 3.69678 14.5833H7.25011C7.52625 14.5833 7.75011 14.3595 7.75011 14.0833V8.75ZM4.19678 9.25H6.75011V13.5833H4.19678V9.25ZM12.3035 8.25C12.5796 8.25 12.8035 8.47386 12.8035 8.75V14.0833C12.8035 14.3595 12.5796 14.5833 12.3035 14.5833H8.75012C8.47398 14.5833 8.25012 14.3595 8.25012 14.0833V8.75C8.25012 8.47386 8.47398 8.25 8.75012 8.25H12.3035ZM11.8035 9.25H9.25012V13.5833H11.8035V9.25ZM12.8035 1.91669C12.8035 1.64054 12.5796 1.41669 12.3035 1.41669H8.75012C8.47398 1.41669 8.25012 1.64054 8.25012 1.91669V7.25002C8.25012 7.52616 8.47398 7.75002 8.75012 7.75002H12.3035C12.5796 7.75002 12.8035 7.52616 12.8035 7.25002V1.91669ZM9.25012 2.41669H11.8035V6.75002H9.25012V2.41669Z"
                  ></path>
                </svg>
                <div>
                  <span className="font-medium text-[14px]">Ad set</span>
                  {/* <p>for 1 compaign</p> */}
                  {selectedRows?.campaigns?.length > 0 && (
                    <div className="">
                      for {selectedRows?.campaigns?.length} Campaigns
                    </div>
                  )}
                </div>
                {selectedRows?.adSets?.length > 0 && (
                  <div className="rounded-[20px] p-3 border">
                    {selectedRows?.adSets?.length} Selected
                  </div>
                )}
              </div>
              <div
                onClick={() => setCurrentTable("ads")}
                // className="flex gap-3 items-center h-full py-7 cursor-pointer hover:border-b-[3px] border-b-[#F7D901]"
                className={`flex gap-3 px-6 items-center h-full py-7 cursor-pointer border-b-[3px]   ${
                  currentTable === "ads"
                    ? "border-b-[#F7D901]"
                    : "border-b-transparent"
                }`}
              >
                {/* <LuRectangleVertical /> */}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  class="sds-icon sds-icon-ad"
                  fill="#444546"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.6666 2.00004C12.6666 1.63185 12.3681 1.33337 11.9999 1.33337H3.99992C3.63173 1.33337 3.33325 1.63185 3.33325 2.00004V14C3.33325 14.3682 3.63173 14.6667 3.99992 14.6667H11.9999C12.3681 14.6667 12.6666 14.3682 12.6666 14V2.00004ZM4.66659 2.66671H11.3333V13.3334H4.66659V2.66671ZM9.9648 11.3007C9.8388 11.548 9.54013 11.758 9.1808 11.758C8.58813 11.758 8.12146 11.3334 8.12146 10.5214V10.484C8.12146 9.68137 8.58346 9.21003 9.1948 9.21003C9.5868 9.21003 9.82946 9.3827 9.9648 9.63003V8.16003H10.6368V11.7067H9.9648V11.3007ZM8.81213 10.5074C8.81213 10.9927 9.04546 11.2307 9.38146 11.2307C9.73613 11.2307 9.98346 10.9927 9.98346 10.498V10.4607C9.98346 9.97537 9.7688 9.72803 9.40013 9.72803C9.02213 9.72803 8.81213 9.9847 8.81213 10.47V10.5074ZM7.02735 11.4127C6.88735 11.6087 6.67268 11.758 6.29002 11.758C5.84668 11.758 5.47335 11.5434 5.47335 11.03C5.47335 10.4607 5.99602 10.246 6.74268 10.246H7.01802V10.148C7.01802 9.8587 6.92935 9.70004 6.62135 9.70004C6.35535 9.70004 6.23402 9.83537 6.20602 10.0454H5.56668C5.60868 9.4667 6.06602 9.21003 6.66335 9.21003C7.26068 9.21003 7.68535 9.4527 7.68535 10.1154V11.7067H7.02735V11.4127ZM6.13602 10.9927C6.13602 11.17 6.24335 11.2867 6.49068 11.2867C6.78935 11.2867 7.01802 11.1234 7.01802 10.8527V10.6474H6.75668C6.36468 10.6474 6.13602 10.7314 6.13602 10.9927Z"
                  ></path>
                </svg>

                <div>
                  <span className="font-medium text-[14px]"> Ads</span>
                  {selectedRows?.adSets?.length > 0 ? (
                    <div className="">
                      for {selectedRows?.adSets?.length} ad set
                    </div>
                  ) : (
                    selectedRows?.campaigns?.length > 0 && (
                      <div className="">
                        for {selectedRows?.campaigns?.length} Campaigns
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="flex gap-3 items-center">
              {currentTable == "campaigns" && (
                <>
                  <button
                    onClick={() => setIsCampaignModal(true)}
                    className="flex gap-1 items-center rounded-full px-[16px] bg-black text-white hover:border-[#F7D901] border-[2px] border-transparent py-2 min-h-auto text-[12px] font-bold"
                  >
                    <MdAdd size={20} />
                    Create Campaign
                  </button>
                </>
              )}
              {currentTable == "adSets" && (
                <>
                  {/* setCreateAdSetModal(true) */}
                  <button
                    onClick={() => {
                      setCreateAdSetModal(true);
                      setAdDetails(null);
                    }}
                    className="flex gap-1 items-center rounded-full px-[16px] bg-black text-white hover:border-[#F7D901] border-[2px] border-transparent py-2 text-[12px] font-bold"
                  >
                    <MdAdd size={20} />
                    Create Ad Set
                  </button>
                </>
              )}
              {currentTable == "ads" && (
                <>
                  {/* <button className="rounded-2xl bg-[#E1E3E5] p-3">
                    Run creative split test
                  </button> */}
                  <button
                    // onClick={() => setIsSingleAd(true)}
                    onClick={() => setCreateAdModal(true)}
                    className="flex gap-1 items-center rounded-full px-[16px] bg-black  text-white py-2 text-[12px] font-bold"
                  >
                    <MdAdd size={20} />
                    Create Ad
                  </button>
                </>
              )}
            </div>
          </div>
          {currentTable === "campaigns" && (
            <CompaignsTable
              setIsSingleCampaign={setIsSingleCampaign}
              setCampaignDetails={setCampaignDetails}
              oldObjectives={oldObjectives}
              getAllCompaigns={getAllCompaigns}
              compaigns={compaigns}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              loading={loading}
              searchQuery={searchTerm}
            />
          )}
          {currentTable === "adSets" && (
            <AdSetsTable
              setAdSetDetails={setAdSetDetails}
              setIsSingleAdSet={setIsSingleAdSet}
              getAllAdSets={getAllAdSets}
              adSets={adSets}
              setAdSets={setAdSets}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              loading={loading}
              searchQuery={searchTerm}
            />
          )}
          {currentTable === "ads" && (
            <AdsTable
              setAdDetails={setAdDetails}
              setIsSingleAd={setIsSingleAd}
              getAllAds={getAllAds}
              ads={ads}
              setAds={setAds}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              loading={loading}
              searchQuery={searchTerm}
            />
          )}
        </div>
      </div>
      <CreateCampaignModal
        setCreateCampaignModal={setIsCampaignModal}
        createCampaignModal={isCampaignModal}
        setIsSingleCampaign={setIsSingleCampaign}
        oldObjectives={oldObjectives}
        setCurrentCampaignDetails={setCampaignDetails}
      />
      <SingleCampaign
        isSingleCampaign={isSingleCampaign}
        setIsSingleCampaign={setIsSingleCampaign}
        setCreateCampaignModal={setIsCampaignModal}
        currentCampaignDetails={campaignDetails}
        setCurrentCampaignDetails={setCampaignDetails}
        getAllCompaigns={getAllCompaigns}
      />
      <SingleAdSet
        isSingleAdSet={isSingleAdSet}
        setIsSingleAdSet={setIsSingleAdSet}
        currentAdSetDetails={adSetDetails}
        getAllAdSets={getAllAdSets}
      />
      <CreateAdSetModal
        setCreateAdSetModal={setCreateAdSetModal}
        createAdSetModal={createAdSetModal}
        setIsSingleAdSet={setIsSingleAdSet}
        setAdSetDetails={setAdSetDetails}
      />

      <SingleAd
        isSingleAd={isSingleAd}
        setIsSingleAd={setIsSingleAd}
        currentAdDetails={adDetails}
        getAllAds={getAllAds}
      />
      <CreateAdModal
        setCreateAdModal={setCreateAdModal}
        createAdModal={createAdModal}
        setIsSingleAd={setIsSingleAd}
        setAdDetails={setAdDetails}
      />
    </>
  );
};

export default Compaigns;
