import React, { useEffect, useState, useRef } from "react";

import { toast } from "react-toastify";
import {
  TextField,
  Modal,
  Backdrop,
  FormControlLabel,
  Switch,
  Checkbox,
  Box,
  FormGroup,
  FormControl,
  RadioGroup,
  Radio,
} from "@mui/material";
import moment from "moment/moment";
import axios from "../../axoisConfig";
import { useStateContext } from "../../context/ContextProvider";
import { MdClose } from "react-icons/md";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { selectStyles } from "../_elements/SelectStyles.jsx";
import dayjs from "dayjs";
import Select from "react-select";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { IoIosArrowUp } from "react-icons/io";
import { IoAddCircleOutline, IoSearch } from "react-icons/io5";
import TimeScheduleView from "./TimeScheduleView.js";
import { FaMinus } from "react-icons/fa";
import { TiArrowSortedDown } from "react-icons/ti";
import Scheduler from "./timeFrame.js";
import LocationSelector from "./location.js";
import { RxCross2 } from "react-icons/rx";
import Audiences from "./audience.js";
import Placements from "./Placements.js";
import utc from "dayjs/plugin/utc";
import { debounce } from "lodash";

const style = {
  transform: "translate(0%, 0%)",
  boxShadow: 24,
};

const stripeStyle = {
  backgroundColor: "#e5e7eb", // Light gray background
  backgroundImage:
    "linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent)",
  backgroundSize: "20px 20px", // Size of the stripes
};
const token = localStorage?.getItem("auth-token");
const performanceGoals = [
  {
    label: "Maximise Reach of ads",
    value: "REACH",
  },
  {
    label: "Maximise number of impressions",
    value: "IMPRESSIONS",
  },
  {
    label: "Maximise number of link clicks",
    value: "LINK_CLICKS",
  },
  {
    label: "Maximise landing pages views",
    value: "LANDING_PAGE_VIEWS",
  },
  {
    label: "Maximise number of conversations",
    value: "CONVERSATIONS",
  },
  {
    label: "Maximise number of instagram profile visits",
    value: "VISIT_INSTAGRAM_PROFILE",
  },
];
const chargedTypes = [
  {
    label: "Impression",
    value: "IMPRESSIONS",
  },
  {
    label: "Link Clicks",
    value: "LINK_CLICKS",
  },
];
const budgetTypes = [
  {
    label: "Daily Budget",
    value: 1,
  },
  {
    label: "Lifetime Budget",
    value: 2,
  },
];
const dailyBudgetTypes = [
  {
    label: "Increase daily budget by value amount (Rs) ",
    value: "ABSOLUTE",
  },
  {
    label: "Increase daily budget by value percentage (%)",
    value: "MULTIPLIER",
  },
];
const mobileDevices = [
  {
    label: "All mobile devices",
    value: "all",
  },
  {
    label: "Android Devices only",
    value: "Android",
  },
  {
    label: "iOS devices only",
    value: "iOS",
  },
];
const minAges = [18, 19, 20, 21, 22, 23, 24, 25].map((age) => ({
  label: `${age}`,
  value: age,
}));

const devices = [
  { label: "Mobile", value: "mobile" },
  { label: "Desktop", value: "desktop" },
];

const SingleAdSet = ({
  isSingleAdSet,
  setIsSingleAdSet,
  currentAdSetDetails,
  getAllAdSets,
  adsetData,
  setAdsetData,
  singleCampData,
  setSingleCampData,
  fb_token,
  createAdSet,
}) => {
  const {
    isLangRTL,
    BACKEND_URL,
    i18n,
    currentMode,
    t,
    snapchatCredentials,
    themeBgImg,
    darkModeColors,
    primaryColor,
    fbCredentials,
  } = useStateContext();
  const Ages = [
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    58,
    59,
    60,
    61,
    62,
    63,
    64,
    "65+",
  ].map((age) => ({
    label: `${age}`,
    value: age,
  }));
  const [isClosing, setIsClosing] = useState(false);
  const [scheduleBudget, setScheduleBudget] = useState("");

  const [os, setOS] = useState("all");
  const [placements, setPlacements] = useState(false);
  const [checked, setChecked] = useState(false);
  const [spendLimitCheck, setSpendLimitCheck] = useState(false);
  const [isChangesOccured, setIsChangesOccured] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [selectedConversionLocation, setSelectedConversionLocation] =
    useState(null);
  const [filterPerformanceGoal, setFilterGoal] = useState(performanceGoals);
  // const [bidStrategy, setBidStrategy] = useState("AUTO_BID");
  const [adSetDetails, setAdSetDetails] = useState({
    name: "",
    status: false,
    budget: null,
    isBidMicro: false,
    bidMicro: null,
    bidStrategy: "AUTO_BID",
  });
  const [localData, setLocalData] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  });
  const [localeOptions, setLocaleOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchloc, setSearchLoc] = useState("");
  const [selectedLocales, setSelectedLocale] = useState(null);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [devicesResult, setDevicesResult] = useState([]);
  const [includedDevice, setIncludedDevice] = useState([]);
  const [excludedDevice, setExcludedDevice] = useState([]);
  const [osVersionsResult, setVersionResult] = useState([]);

  const [minVersion, setMinVersion] = useState(null);
  const [maxVersion, setMaxVersion] = useState(null);
  const [loadingData, setLoadingData] = useState({
    include: false,
    exclude: false,
    min: false,
    max: false,
  });

  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loadingLocations, setLoadingLocations] = useState(false);

  console.log("selected local:: ", selectedLocales);
  console.table("min,max versions: ", minVersion, maxVersion);

  const handleSelectVersion = (selectedOptions, type) => {
    // Update the local state for the selected options
    if (type === "min") {
      setMinVersion(selectedOptions);
    } else {
      setMaxVersion(selectedOptions);
    }

    setAdsetData((prevData) => {
      // Get current min and max values from state or the selected option
      const currentMin =
        type === "min" ? selectedOptions?.label : minVersion?.label;
      const currentMax =
        type === "max" ? selectedOptions?.label : maxVersion?.label;

      // Construct the range if both min and max are set
      const osVersionRange =
        currentMin && currentMax ? [`${currentMin}-${currentMax}`] : [];

      return {
        ...prevData,
        targeting: {
          ...prevData.targeting,
          user_os: osVersionRange, // Update the user_os_version field
        },
      };
    });
  };

  const handleSelectDevices = (selectedOptions, type) => {
    // Update the local state for the selected options
    if (type === "include") {
      setIncludedDevice(selectedOptions);
    } else {
      setExcludedDevice(selectedOptions);
    }

    let key = type == "include" ? "user_device" : "excluded_user_device";

    // Update the ad set targeting
    setAdsetData((prevData) => ({
      ...prevData,
      targeting: {
        ...prevData.targeting,
        [key]: selectedOptions?.map((option) => option.label),
      },
    }));
  };

  const handleSearchChange = (query) => {
    setSearchLoc(query);
    fetchLocations(query);
  };

  const fetchLocations = (query) => {
    if (!query || query.length < 3) {
      setLocationOptions([]);
      return;
    }

    setLoadingLocations(true);

    // Call Facebook Graph API /search
    window.FB.api(
      "/search",
      "GET",
      {
        type: "adgeolocation",
        q: query,
        location_types: ["city", "region", "country"], // Adjust types based on need
        access_token: fb_token,
      },
      (response) => {
        if (response?.data) {
          console.log("locations response: ", response);

          // Map the response to the format required by the <Select> component
          const options = response.data.map((loc) => ({
            label: `${loc.name}, ${loc.country_name}`,
            value: loc.key,
            type: loc.type, // Identify type: city, region, or country
            radius: loc.radius || null, // Optional radius for cities
            distance_unit: loc.distance_unit || "mile", // Default distance unit
          }));
          setLocationOptions(options); // Update dropdown options
        } else {
          console.error("Error fetching locations:", response?.error);
          toast.error(
            ` ${response?.error?.message || `Error fetching locations`}.`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }

        setLoadingLocations(false); // Clear loading state
      }
    );
  };

  const handleLocationChange = (selectedOption) => {
    setSelectedLocation(selectedOption); // Update the selected location state

    // Update ad set data with the selected location
    setAdsetData((prevData) => {
      const updatedGeoLocations = { ...prevData.targeting.geo_locations };

      // Categorize the selected location
      if (selectedOption.type === "country") {
        updatedGeoLocations.countries = [
          ...(updatedGeoLocations.countries || []),
          selectedOption.value,
        ];
      } else if (selectedOption.type === "region") {
        updatedGeoLocations.regions = [
          ...(updatedGeoLocations.regions || []),
          { key: selectedOption.value },
        ];
      } else if (selectedOption.type === "city") {
        updatedGeoLocations.cities = [
          ...(updatedGeoLocations.cities || []),
          {
            key: selectedOption.value,
            radius: selectedOption.radius || 10, // Default radius
            distance_unit: selectedOption.distance_unit || "mile",
          },
        ];
      }

      return {
        ...prevData,
        targeting: {
          ...prevData.targeting,
          geo_locations: updatedGeoLocations,
        },
      };
    });
  };

  const handleClose = () => {
    setIsClosing(true);

    setTimeout(() => {
      setIsClosing(false);
      setIsSingleAdSet(false);
    }, 1000);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
    setAdsetData({
      ...adsetData,
      end_time: "",
    });
  };

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  const placementTypes = [
    {
      label: "Advantage+ placements (Recommended)",
      value: 1,
      para: "Advantage+ placements (Recommended) Use Advantage+ placements to maximise your budget and help show your ads to more people. Facebook's delivery system will allocate your ad set's budget across multiple placements based on where they're likely to perform best.",
    },
    {
      label: "Manual placements",
      value: 2,
      para: "Manually choose the places to show your ad. The more placements you select, the more opportunities you'll have to reach your target audience and achieve your business goals.",
    },
  ];
  const inventoryFilters = [
    {
      label: "Expanded inventory",
      value: 1,
      para: "Show ads on all content that adhere to our Content Monetisation Policies so that you get the most reach.",
    },
    {
      label: "Moderate inventory",
      value: 2,
      para: "Exclude highly sensitive content",
    },
    {
      label: "Limited inventory",
      value: 3,
      para: "Exclude additional sensitive content, as well as all live videos. This lowers reach and can increase costs.",
    },
  ];

  const conversionLocations = [
    {
      label: "Website",
      value: "WEBSITE",
      para: "Send traffic to your website.",
    },
    {
      label: "App",
      value: "APP",
      para: "Send traffic to your app.",
    },
    {
      label: "Messaging apps",
      value: "MESSENGER",
      para: "Send traffic to Messenger, Instagram and WhatsApp.",
    },
    {
      label: "Instagram profile",
      value: "INSTAGRAM_PROFILE",
      para: "Send traffic to your Instagram profile.",
    },
    // {
    //   label: "Calls",
    //   value: 5,
    //   para: "Get people to call your business.",
    // },
  ];

  const objectiveTypes = [
    "awareness",
    "traffic",
    "engagement",
    "leads",
    "app_promotion",
    "sales",
  ];
  const selectedObjective = objectiveTypes[1];

  if (selectedObjective === "engagement") {
    conversionLocations.push(
      {
        label: "On your ad",
        value: 6,
        para: "Get people to watch a video or interact with your post or event.",
      },
      {
        label: "Facebook Page",
        value: 6,
        para: "Get people to engage with your Facebook Page.",
      }
    );
  }
  if (selectedObjective === "leads") {
    conversionLocations.push(
      {
        label: "Instant Forms",
        value: 6,
        para: "Generate leads by asking people to fill in a form.",
      },
      {
        label: "Instant Forms and Messenger",
        value: 7,
        para: "Generate leads by asking people to fill in a form or by starting chats in Messenger.",
      }
    );
  }

  const handlePlatformChange = (event) => {
    const { name, checked } = event.target;

    setSelectedPlatforms((prevPlatforms) => {
      if (checked) {
        // Add the selected platform
        return [...prevPlatforms, name];
      } else {
        // Remove the deselected platform
        return prevPlatforms.filter((platform) => platform !== name);
      }
    });

    // Update adsetData with the selected platforms
    setAdsetData((prevData) => ({
      ...prevData,
      targeting: {
        ...prevData.targeting,
        publisher_platforms: checked
          ? [...(prevData.targeting.publisher_platforms || []), name]
          : prevData.targeting.publisher_platforms.filter(
              (platform) => platform !== name
            ),
      },
    }));
  };

  const handleInputChange = (query) => {
    setSearchQuery(query);
    fetchLocales(query);
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedLocale(selectedOptions);

    const localeIds = selectedOptions?.map((option) => option.value) || [];
    setAdsetData((prevData) => ({
      ...prevData,
      targeting: {
        ...prevData.targeting,
        locales: localeIds,
      },
    }));
  };

  // const fetchLocales = debounce((query) => {
  //   if (window.FB) {
  //     window.FB.api(
  //       `/search`,
  //       "GET",
  //       { type: "adlocale", q: query, access_token: fb_token },
  //       (response) => {
  //         if (response && response?.data) {
  //           const options = response?.data?.map((locale) => ({
  //             value: locale.key,
  //             label: locale.name,
  //           }));
  //           setLocaleOptions(options);
  //         } else {
  //           setLocaleOptions([]);
  //           console.error("Error fetching locales:", response);
  //           toast.error(
  //             `${
  //               response.error
  //                 ? response.error.message
  //                 : "Error fetching languages."
  //             }`,
  //             {
  //               position: "top-right",
  //               autoClose: 3000,
  //               hideProgressBar: false,
  //               closeOnClick: true,
  //               pauseOnHover: true,
  //               draggable: true,
  //               progress: undefined,
  //               theme: "light",
  //             }
  //           );
  //         }
  //       }
  //     );
  //   } else {
  //     console.error("Facebook SDK is not initialized.");
  //   }
  // }, 500);

  const handleInventoryChange = (selectedFilter) => {
    setSelectedDetails(selectedFilter); // Update UI state

    // Update the ad set data
    setAdsetData((prevData) => ({
      ...prevData,
      inventory_type: mapInventoryValueToType(selectedFilter?.value),
    }));
    // setAdsetData((prevData) => ({
    //   ...prevData,
    //   targeting: {
    //     ...prevData.targeting,
    //     inventory_type: mapInventoryValueToType(selectedFilter.value),
    //   },
    // }));
  };

  // Helper function to map inventory filter values to inventory_type
  const mapInventoryValueToType = (value) => {
    switch (value) {
      case 1:
        return "EXPANDED";
      case 2:
        return "MODERATE";
      case 3:
        return "LIMITED";
      default:
        return "MODERATE"; // Default to MODERATE if no match
    }
  };

  const fetchOSVersions = async (type, query, class_name, setResults) => {
    setLoadingData({
      ...loadingData,
      [query]: true,
    });

    try {
      const response = await axios.get(`http://localhost:5000/api/search`, {
        params: {
          type,
          class_name,
        },
      });

      console.log("os versions::: ", response);

      if (response?.data?.data) {
        let data = response?.data?.data;
        let versions;

        if (os === "Android") {
          data = data?.find((data) => data?.platform == "Android");
          const splitVersions = data?.description?.split(";");
          versions = splitVersions;
        } else {
          data = data?.find((data) => data?.platform == "iOS");
          const splitVersions = data?.description?.split(";");
          versions = splitVersions;
        }

        console.log("versions: ", versions);

        // Map the filtered data to setResults
        setResults(
          versions?.map((item, index) => ({
            value: index,
            label: item,
          }))
        );
      } else {
        setResults([]);
        console.error("No os versions found.");
        toast.error(` No os versions found.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      setLoadingData({
        ...loadingData,
        [query]: false,
      });
    } catch (error) {
      setLoadingData({
        ...loadingData,
        [query]: false,
      });

      console.error("Error fetching os versions:", error);
      toast.error(
        ` ${error?.response?.data?.error_msg || `Error fetching os versions`}.`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  const fetchTargetingDevices = async (type, query, class_name, setResults) => {
    setLoadingData({
      ...loadingData,
      [query]: true,
    });

    try {
      const response = await axios.get(
        `http://localhost:5000/api/targeting-search`,
        {
          params: {
            type,
            class_name,
          },
        }
      );

      console.log("behavior targeting res::: ", response);

      if (response?.data?.data) {
        let filterData = response?.data?.data?.data;

        // Conditionally filter the data
        if (os === "Android") {
          filterData = filterData?.filter(
            (item) => item.platform === "Android"
          );
        } else if (os === "iOS") {
          filterData = filterData?.filter((item) => item.platform === "iOS");
        }

        // Map the filtered data to setResults
        setResults(
          filterData?.map((item, index) => ({
            value: index,
            label: item.name,
          }))
        );
      } else {
        setResults([]);
        console.error("No targeting options found.");
        toast.error(` No devices found.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      setLoadingData({
        ...loadingData,
        [query]: false,
      });
    } catch (error) {
      setLoadingData({
        ...loadingData,
        [query]: false,
      });

      console.error("Error fetching devices options:", error);
      toast.error(
        ` ${error?.response?.data?.error_msg || `Error fetching ${type}`}.`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  const fetchLocales = debounce(async (query) => {
    if (!query) {
      setLocaleOptions([]);
      return;
    }

    try {
      const response = await axios.get(
        `http://localhost:5000/api/search?q=${query}`
      );

      console.log("response:: ", response);

      if (response?.data?.data) {
        const options = response?.data?.data?.map((locale) => ({
          value: locale?.key,
          label: locale?.name,
        }));
        setLocaleOptions(options);
      } else {
        setLocaleOptions([]);
        console.error("Error fetching locales: Invalid data format");
        toast.error("Error fetching languages.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      setLocaleOptions([]);
      console.error("Error fetching locales:", error);
      toast.error(
        error.response?.data?.error?.message || "Error fetching languages.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  }, 500);

  const handleBudgetScheduleUpdate = () => {
    dayjs.extend(utc);
    console.log("end time : ");

    if (localData.startDate && localData.startTime) {
      const timeStart = dayjs(localData.startDate)
        .hour(dayjs(localData.startTime).hour())
        .minute(dayjs(localData.startTime).minute())
        .utc()
        .unix(); // Convert to UNIX timestamp in seconds

      setAdsetData((prev) => ({
        ...prev,
        start_time: timeStart,
      }));

      if (localData.endDate && localData.endTime) {
        const timeEnd = dayjs(localData.endDate)
          .hour(dayjs(localData.endTime).hour())
          .minute(dayjs(localData.endTime).minute())
          .utc()
          .unix(); // Convert to UNIX timestamp in seconds

        setAdsetData((prev) => ({
          ...prev,
          end_time: timeEnd,
        }));
      }
    } else {
      console.error("No start or end datetime provided");
    }
  };

  useEffect(() => {
    fetchTargetingDevices(
      "adTargetingCategory",
      "include",
      "user_device",
      setDevicesResult
    );
    fetchOSVersions("adTargetingCategory", "min", "user_os", setVersionResult);
  }, [os]);
  useEffect(() => {
    handleBudgetScheduleUpdate();
  }, [
    localData.startDate,
    localData.startTime,
    localData.endDate,
    localData.endTime,
  ]);

  useEffect(() => {
    const conversionLocation = adsetData?.destination_type;
    let filterPerformance = filterPerformanceGoal;
    if (conversionLocation == "APP") {
      filterPerformance = performanceGoals?.filter(
        (perf) =>
          perf.value?.toLowerCase() === "reach" ||
          perf?.value?.toLowerCase() === "link_clicks"
      );
    } else if (conversionLocation == "MESSENGER") {
      filterPerformance = performanceGoals?.filter(
        (perf) => perf.value?.toLowerCase() !== "landing_page_views"
      );
    } else if (conversionLocation == "PROFILE") {
      filterPerformance = performanceGoals?.filter(
        (perf) => perf.value?.toLowerCase() === "visit_instagram_profile"
      );
    } else if (conversionLocation == "WEBSITE") {
      filterPerformance = performanceGoals;
    } else {
      filterPerformance = performanceGoals;
    }

    setFilterGoal(filterPerformance);
    setAdsetData({
      ...adsetData,
      optimization_goal: "",
    });
  }, [adsetData?.destination_type]);

  return (
    <>
      <Modal
        // keepMounted
        open={isSingleAdSet}
        onClose={() => setIsSingleAdSet(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }
        w-[100vw] h-[100vh] flex items-start justify-end `}
        >
          <button
            // onClick={handleCloseTimelineModel}
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className=" hover:border hover:border-white hover:rounded-full"
            />
          </button>
          {/* ${
              currentMode === "dark"
                ? "bg-[#000000] text-white"
                : "bg-[#F9F9FA] text-black"
            }  */}
          <div
            style={style}
            className={` ${getSummaryBgClass()} ${
              currentMode === "dark"
                ? "bg-gradient-to-br from-[#3A3F47] to-[#20272C]"
                : "bg-gradient-to-br from-[#F3EEF0] to-[#E7F0F9]"
            } ${
              isLangRTL(i18n.language)
                ? currentMode === "dark" && " border-primary border-r-2"
                : currentMode === "dark" && " border-primary border-l-2"
            } 
             pt-4 h-[100vh] w-[80vw] overflow-y-scroll border-primary px-7 flex flex-col gap-4 relative
            `}
          >
            {/* className="bg-white rounded-3xl px-8 py-4 shadow-md mb-5" */}
            <h2 className="font-bold text-[16px] mb-5">Ad Set Details</h2>

            <div
              className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4`}
            >
              <label htmlFor="" className="text-[14px] font-semibold mb-3">
                Ad set name
              </label>
              <input
                type="text"
                className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                value={adsetData?.name}
                onChange={(e) =>
                  setAdsetData((pre) => ({
                    ...pre,
                    name: e?.target?.value,
                  }))
                }
              />
            </div>

            <div
              className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4`}
            >
              <label htmlFor="" className="text-[14px] font-semibold mb-3">
                {selectedObjective !== "awareness" &&
                selectedObjective !== "app_promotion"
                  ? "Convertion"
                  : selectedObjective == "awareness"
                  ? "Awareness"
                  : "App"}
              </label>
              {selectedObjective === "traffic" && (
                <div>
                  <label htmlFor="" className="text-[12px] font-semibold mb-3">
                    Conversion location
                  </label>
                  <div className="mb-4 rounded p-2">
                    <p>
                      Choose where you want to drive traffic. You'll enter more
                      details about the destination later.
                    </p>
                    <ul className="pl-4 pt-4 w-1/2 px-4">
                      {conversionLocations?.map((objective) => {
                        return (
                          <li
                            className={`flex gap-4 items-center mb-8 group cursor-pointer px-2 py-2 rounded-lg  ${
                              selectedConversionLocation?.label ===
                              objective?.label
                                ? "bg-[#C3DCF5]"
                                : ""
                            }`}
                            onClick={() => {
                              setSelectedConversionLocation(objective);
                              setAdsetData({
                                ...adsetData,
                                destination_type: objective?.value,
                              });
                            }}
                          >
                            {" "}
                            <div
                              className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                                selectedConversionLocation?.label ===
                                objective?.label
                                  ? "border-[5px]"
                                  : "group-hover:border-[3px]"
                              }  `}
                            ></div>
                            <div className="flex-1">
                              <h3
                                className={`text-[14px] ${
                                  currentMode === "dark"
                                    ? selectedConversionLocation?.label ===
                                      objective?.label
                                      ? "text-black"
                                      : "text-white"
                                    : "text-black"
                                }`}
                              >
                                {objective?.label}
                              </h3>
                              <p>{objective?.para}</p>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
              {selectedObjective === "app_promotion" && (
                <>
                  <h3 className="text-[14px] font-semibold my-3">
                    Mobile app store
                  </h3>
                  <div className="w-full ">
                    <Select
                      // isLoading={adSetLoading}
                      id="goals"
                      // options={performanceGoals}
                      options={filterPerformanceGoal}
                      // value={adsetData?.optimization_goal}
                      // onChange={(e) => {
                      //   setAdsetData((pre) => ({
                      //     ...pre,
                      //     optimization_goal: e.value,
                      //   }));
                      // }}
                      placeholder={"Select Store"}
                      // className={`mb-5`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                  </div>{" "}
                  <h3 className="text-[14px] font-semibold my-3">App</h3>
                  <div className="w-full ">
                    <Select
                      // isLoading={adSetLoading}
                      id="goals"
                      // options={performanceGoals}
                      options={filterPerformanceGoal}
                      // value={adDetails?.publicProfile}
                      // onChange={(e) => {
                      //   setAdDetails((pre) => ({
                      //     ...pre,
                      //     publicProfile: e,
                      //   }));
                      // }}
                      placeholder={"Select App"}
                      // className={`mb-5`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                  </div>
                </>
              )}
              <h3 className="text-[14px] font-semibold my-3">
                Performance goal
              </h3>
              <div className="w-full ">
                <Select
                  // isLoading={adSetLoading}
                  id="goals"
                  // options={performanceGoals}
                  options={filterPerformanceGoal}
                  value={filterPerformanceGoal?.find(
                    (perf) =>
                      perf?.value?.toLowerCase() ===
                      adsetData?.optimization_goal?.toLowerCase()
                  )}
                  onChange={(e) => {
                    setAdsetData((pre) => ({
                      ...pre,
                      optimization_goal: e.value,
                    }));
                  }}
                  placeholder={"Select Performance goal"}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
              </div>
              {selectedConversionLocation?.label === "App" && (
                <>
                  <h3 className="text-[14px] font-semibold my-3">
                    Mobile app store
                  </h3>
                  <div className="w-full ">
                    <Select
                      // isLoading={adSetLoading}
                      id="goals"
                      // options={performanceGoals}
                      options={filterPerformanceGoal}
                      // value={adDetails?.publicProfile}
                      // onChange={(e) => {
                      //   setAdDetails((pre) => ({
                      //     ...pre,
                      //     publicProfile: e,
                      //   }));
                      // }}
                      placeholder={"Select Store"}
                      // className={`mb-5`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                  </div>{" "}
                  <h3 className="text-[14px] font-semibold my-3">App</h3>
                  <div className="w-full ">
                    <Select
                      // isLoading={adSetLoading}
                      id="goals"
                      // options={performanceGoals}
                      options={filterPerformanceGoal}
                      // value={adDetails?.publicProfile}
                      // onChange={(e) => {
                      //   setAdDetails((pre) => ({
                      //     ...pre,
                      //     publicProfile: e,
                      //   }));
                      // }}
                      placeholder={"Select App"}
                      // className={`mb-5`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                  </div>
                </>
              )}
              <p>
                To help us improve delivery, we may survey a small section of
                your audience.
              </p>
              {(selectedObjective === "awareness" ||
                selectedConversionLocation?.label === "Messaging apps" ||
                selectedConversionLocation?.label === "Calls" ||
                selectedConversionLocation?.label === "Facebook Page" ||
                selectedConversionLocation?.label === "Instant Forms" ||
                selectedConversionLocation?.label ===
                  "Instant Forms and Messenger") && (
                <>
                  <h3 className="text-[14px] font-semibold my-3">
                    Facebook Page
                  </h3>
                  <div className="w-full ">
                    <Select
                      // isLoading={adSetLoading}
                      id="goals"
                      // options={performanceGoals}
                      options={filterPerformanceGoal}
                      // value={adDetails?.publicProfile}
                      // onChange={(e) => {
                      //   setAdDetails((pre) => ({
                      //     ...pre,
                      //     publicProfile: e,
                      //   }));
                      // }}
                      placeholder={"Select Facebook Page"}
                      // className={`mb-5`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                  </div>
                  {selectedConversionLocation?.label === "Messaging apps" && (
                    <>
                      <h3 className="text-[14px] font-semibold my-3">
                        Messaging apps
                      </h3>
                      <p>
                        Choose at least one destination where you are available
                        to chat. If you select multiple apps, we'll send people
                        who tap on your ad to the app where they're most likely
                        to chat with you.
                      </p>
                      {["Messenger", "Instagram", "Whatsapp"]?.map(
                        (platform) => {
                          return (
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      // checked={state.checkbox3}
                                      // onChange={handleChange}
                                      name="checkbox3"
                                    />
                                  }
                                  label=""
                                />
                                <div>
                                  <h3 className="text-[14px] font-semibold my-3">
                                    {platform}
                                  </h3>
                                </div>
                              </div>

                              <button
                                // onClick={(event) => setAnchorEl(event.currentTarget)}
                                className="px-4 py-2 border border-gray-300 mt-3 rounded-lg flex gap-3 items-center w-fit"
                              >
                                Connect Account
                              </button>
                            </div>
                          );
                        }
                      )}
                    </>
                  )}
                </>
              )}
              <label htmlFor="" className="text-[14px] font-semibold my-3">
                Cost per result goal∙ Optional
              </label>
              <div className="relative w-full">
                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                  $
                </span>
                <input
                  type="number"
                  // className="pl-6 p-2 py-3 w-full mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                  className={`pl-6 p-2 py-3 w-full mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                  value={adsetData?.bid_amount}
                  placeholder="No cap"
                  onChange={(e) =>
                    setAdsetData((pre) => ({
                      ...pre,
                      bid_amount: e?.target?.value,
                    }))
                  }
                />
              </div>
              <p className="py-3 ">
                Meta will aim to spend your entire budget and get the most video
                plays (2-second continuous) using the highest-volume bid
                strategy. If keeping the average cost per result around a
                certain amount is important, enter a cost per result goal.
              </p>
              <h3 className="text-[14px] font-semibold my-3">
                {" "}
                When you are charged
              </h3>
              <div className="w-full ">
                <Select
                  // isLoading={adSetLoading}
                  id="goals"
                  options={chargedTypes}
                  value={chargedTypes?.find(
                    (type) => type?.value == adsetData?.billing_event
                  )}
                  onChange={(e) => {
                    setAdsetData((pre) => ({
                      ...pre,
                      billing_event: e.value,
                    }));
                  }}
                  placeholder={""}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
              </div>
              {/* <h3 className="text-[14px] font-semibold my-3"> Delivery Type</h3>
              <div className="w-full ">
                <Select
                  // isLoading={adSetLoading}
                  id="goals"
                  options={chargedTypes}
                  // value={adDetails?.publicProfile}
                  // onChange={(e) => {
                  //   setAdDetails((pre) => ({
                  //     ...pre,
                  //     publicProfile: e,
                  //   }));
                  // }}
                  placeholder={""}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
              </div> */}
            </div>
            {/* <div
              className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4`}
            >
              <div className="flex items-center justify-between ">
                <label
                  htmlFor=""
                  className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
                >
                  Dynamic creative
                </label>
                <div>
                  <FormControlLabel
                    value=""
                    control={
                      <Switch
                        color="primary"
                        size="medium"
                        // checked={campaignDetails?.status}
                        // onChange={(e) =>
                        //   setCampaignDetials((pre) => ({
                        //     ...pre,
                        //     status: e?.target?.checked,
                        //   }))
                        // }
                      />
                    }
                    label="Off"
                    labelPlacement="start"
                    sx={{
                      margin: "0px",
                      marginTop: `10px`,
                    }}
                  />
                </div>
              </div>
              <p>
                Provide creative elements, such as images and headlines, and
                we'll automatically generate combinations optimised for your
                audience. Variations may include different formats, templates or
                audio based on one or more elements
              </p>
            </div> */}
            <div
              className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4`}
            >
              <label
                htmlFor=""
                className="text-[16px] font-semibold mb-3 flex gap-2 items-center"
              >
                Budget & schedule
              </label>
              <label
                htmlFor=""
                className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
              >
                Budget
              </label>
              <div className="grid grid-cols-2 gap-6 mb-5">
                {singleCampData?.daily_budget != "-" ||
                singleCampData?.daily_budget != null ? (
                  <p className="mb-4">
                    Your budget was set using Advantage campaign budget and you
                    can update your budget at the campaign level.
                  </p>
                ) : (
                  <>
                    <div className="w-full ">
                      <Select
                        // isLoading={adSetLoading}
                        id="public-Profiles"
                        options={budgetTypes}
                        // value={adDetails?.publicProfile}
                        // onChange={(e) => {
                        //   setAdDetails((pre) => ({
                        //     ...pre,
                        //     publicProfile: e,
                        //   }));
                        // }}
                        placeholder={"Select Budget Type"}
                        className={`mt-1`}
                        menuPortalTarget={document.body}
                        styles={selectStyles(currentMode, primaryColor)}
                      />
                    </div>
                    <div className="flex flex-col">
                      <div className="relative w-full">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                          $
                        </span>
                        <input
                          type="number"
                          // className="pl-6 p-2 py-3 w-full mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                          className={`pl-6 p-2 py-3 w-full mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                          // value={adSetDetails?.budget}
                          placeholder="No cap"
                          // onChange={(e) =>
                          //   setAdSetDetails((pre) => ({
                          //     ...pre,
                          //     budget: e?.target?.value,
                          //   }))
                          // }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <label
                htmlFor=""
                className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
              >
                Schedule
              </label>
              <h3 className="text-[14px] font-semibold my-1">Start date</h3>
              <div className="grid grid-cols-2 gap-6 items-center py-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={localData?.startDate || null}
                    label={t("start_date")}
                    views={["day", "month", "year"]}
                    onChange={(val) =>
                      setLocalData((pre) => ({
                        ...pre,
                        startDate: val,
                      }))
                    }
                    format="DD-MM-YYYY"
                    minDate={dayjs()} // Restrict to future dates
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          "& input": {
                            color: currentMode === "dark" ? "white" : "black",
                          },
                          "& .MuiSvgIcon-root": {
                            color: currentMode === "dark" ? "white" : "black",
                          },
                          // "& .MuiOutlinedInput-notchedOutline": {
                          //   borderColor:
                          //     fieldErrors?.date === true &&
                          //     "#DA1F26 !important",
                          // },
                          // marginBottom: "20px",
                        }}
                        fullWidth
                        size="small"
                        {...params}
                        onKeyDown={(e) => e.preventDefault()}
                        readOnly={true}
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Select time"
                    value={localData?.startTime || null}
                    onChange={(newValue) => {
                      setLocalData((pre) => ({
                        ...pre,
                        startTime: newValue,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={handleChange} />
                  }
                  label="Set an End Date "
                />
              </div>
              {checked && (
                <>
                  <h3 className="text-[14px] font-semibold my-1">End Date</h3>
                  <div className="grid grid-cols-2 gap-6 items-center py-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={localData?.endDate || null}
                        label={t("end_date")}
                        views={["day", "month", "year"]}
                        onChange={(val) =>
                          setLocalData((pre) => ({
                            ...pre,
                            endDate: val,
                          }))
                        }
                        format="DD-MM-YYYY"
                        minDate={localData?.startDate || dayjs()} // End date should be after start date
                        disabled={!localData?.startDate}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "& input": {
                                color:
                                  currentMode === "dark" ? "white" : "black",
                              },
                              "& .MuiSvgIcon-root": {
                                color:
                                  currentMode === "dark" ? "white" : "black",
                              },
                              // "& .MuiOutlinedInput-notchedOutline": {
                              //   borderColor:
                              //     fieldErrors?.date === true &&
                              //     "#DA1F26 !important",
                              // },
                              // marginBottom: "20px",
                            }}
                            fullWidth
                            size="small"
                            {...params}
                            onKeyDown={(e) => e.preventDefault()}
                            readOnly={true}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Select time"
                        value={localData?.endTime || null}
                        onChange={(newValue) => {
                          setLocalData((pre) => ({
                            ...pre,
                            endTime: newValue,
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </>
              )}
              <h3 className="text-[14px] font-semibold my-1">
                Ad set spending limits
              </h3>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={spendLimitCheck}
                      onChange={(e) => {
                        setSpendLimitCheck(e.target.checked);
                        setAdsetData({
                          ...adsetData,
                          daily_min_spend_target: "",
                          daily_budget: "",
                        });
                      }}
                    />
                  }
                  label="Ad set spending limits"
                />
              </div>
              {spendLimitCheck ? (
                <div className="grid grid-cols-2 gap-6 items-center py-4">
                  <div>
                    <label
                      htmlFor=""
                      className="text-[14px] font-semibold my-3"
                    >
                      Daily minimum
                    </label>
                    <div className="relative w-full">
                      <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                        $
                      </span>
                      <input
                        type="number"
                        // className="pl-6 p-2 py-3 w-full mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                        className={`pl-6 p-2 py-3 w-full mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                        value={adsetData?.daily_min_spend_target}
                        placeholder="No cap"
                        onChange={(e) =>
                          setAdsetData((pre) => ({
                            ...pre,
                            daily_min_spend_target: e?.target?.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="text-[14px] font-semibold my-3"
                    >
                      Daily maximum
                    </label>
                    <div className="relative w-full">
                      <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                        $
                      </span>
                      <input
                        type="number"
                        // className="pl-6 p-2 py-3 w-full mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                        className={`pl-6 p-2 py-3 w-full mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-gray-200  focus:border-[1px] outline-none !outline-offset-0 focus:outline-[#0A78BE] focus:outline-[1.1px] `}
                        value={adsetData?.daily_budget}
                        placeholder="No cap"
                        onChange={(e) =>
                          setAdsetData((pre) => ({
                            ...pre,
                            daily_budget: e?.target?.value,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <h3 className="text-[14px] font-semibold my-3">
                Budget scheduling
              </h3>
              <p>Schedule budget in campaign.</p>
              {/* 
              <h3 className="text-[14px] font-semibold mt-3">Ad scheduling</h3>
              <p>You'll set the specific schedule within each ad set.</p>
              <div className="">
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={handleChange} />
                  }
                  label="Increase your budget during specific time periods"
                />
              </div>
              <Scheduler /> */}
            </div>

            <div
              className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4`}
            >
              <label
                htmlFor=""
                className="text-[16px] font-semibold mb-3 flex gap-2 items-center"
              >
                Audience controls
              </label>
              {/* <LocationSelector /> */}
              <Select
                id="location-search"
                options={locationOptions}
                value={selectedLocation}
                onChange={(selectedOption) =>
                  handleLocationChange(selectedOption)
                }
                onInputChange={handleSearchChange}
                placeholder="Search for locations..."
                isLoading={loadingLocations}
                className="w-full mb-0"
                menuPortalTarget={document.body}
                styles={selectStyles(currentMode, primaryColor)}
              />

              <label
                htmlFor=""
                className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
              >
                Age
              </label>
              <div className="grid grid-cols-2 w-[250px] gap-3">
                <Select
                  // isLoading={adSetLoading}
                  id="min Age"
                  options={Ages}
                  value={
                    adsetData?.targeting?.age_min
                      ? Ages?.find(
                          (age) => age?.value == adsetData?.targeting?.age_min
                        )
                      : null
                  }
                  onChange={(e) => {
                    setAdsetData((pre) => ({
                      ...pre,
                      targeting: {
                        ...pre.targeting,
                        age_min: e.value,
                      },
                    }));
                  }}
                  placeholder={"Min"}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
                <Select
                  // isLoading={adSetLoading}
                  id="max-ages"
                  options={Ages}
                  value={
                    adsetData?.targeting?.age_max
                      ? Ages?.find(
                          (age) => age?.value == adsetData?.targeting?.age_max
                        )
                      : null
                  }
                  onChange={(e) => {
                    setAdsetData((pre) => ({
                      ...pre,
                      targeting: {
                        ...pre.targeting,
                        age_max: e.value,
                      },
                    }));
                  }}
                  placeholder={"Max"}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
              </div>

              <FormControl component="fieldset" sx={{ marginBottom: "10px" }}>
                {/* <FormLabel component="legend">Gender</FormLabel> */}
                <label
                  htmlFor=""
                  className="text-[14px] font-semibold mb-3 flex gap-2 items-center"
                >
                  Gender
                </label>
                <RadioGroup
                  name="gender"
                  // value={gender}
                  // onChange={handleGenderChange}
                  row // Optional: Makes it horizontal
                  onChange={(e) => {
                    const value = e.target.value;
                    setAdsetData((prev) => ({
                      ...prev,
                      targeting: {
                        ...prev.targeting,
                        genders: value === "all" ? [1, 2] : [parseInt(value)],
                      },
                    }));
                  }}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="All"
                  />
                  <FormControlLabel value="1" control={<Radio />} label="Men" />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Women"
                  />
                </RadioGroup>
              </FormControl>

              <label
                htmlFor=""
                className="text-[16px] font-semibold mb-3 flex gap-2 items-center"
              >
                Languages
              </label>
              <p className="pb-3">
                Only enter a language if you need to limit your audience to
                people who use a language that isn't common to your selected
                locations.
              </p>
              {selectedLocales?.length > 0 ? (
                <>
                  <div
                    // className="bg-[#F2F2F2] rounded-lg py-3 px-4"
                    className={` ${
                      currentMode === "dark" ? "bg-gray-700" : "bg-[#F2F2F2]"
                    } rounded-lg py-3 px-4 mb-3`}
                  >
                    <div
                      //  className="bg-white flex justify-between items-center px-3 rounded-lg py-3 group"

                      className={` ${
                        currentMode === "dark" ? "bg-black" : "bg-white"
                      } flex justify-between items-center px-3 rounded-lg py-3 group`}
                    >
                      <div className="flex gap-4 items-center">
                        {adsetData?.targeting?.locales?.length > 0 ||
                          selectedLocales?.map((locale) => (
                            <div
                              key={locale.value}
                              className="bg-blue-500 text-white px-2 py-1 rounded-md"
                            >
                              {locale.label}
                            </div>
                          ))}
                      </div>
                      {/* <span className="group-hover:block hidden cursor-pointer">
                    <RxCross2 />
                  </span> */}
                    </div>
                  </div>
                </>
              ) : null}

              <div className="w-full ">
                <Select
                  // isLoading={adSetLoading}
                  id="goals"
                  options={localeOptions}
                  value={selectedLocales}
                  onChange={handleSelectChange}
                  onInputChange={handleInputChange}
                  placeholder={"Select Language"}
                  isMulti={true}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
              </div>

              {/* <label
                htmlFor=""
                className="text-[16px] font-semibold mb-3 flex gap-2 items-center"
              >
                Exclude these custom audiences
              </label>
              <div className="w-full ">
                <Select
                  // isLoading={adSetLoading}
                  id="goals"
                  options={minAges}
                  // value={adDetails?.publicProfile}
                  // onChange={(e) => {
                  //   setAdDetails((pre) => ({
                  //     ...pre,
                  //     publicProfile: e,
                  //   }));
                  // }}
                  placeholder={"Select Exclude audiences"}
                  // className={`mb-5`}
                  menuPortalTarget={document.body}
                  styles={selectStyles(currentMode, primaryColor)}
                />
              </div> */}
            </div>

            <div
              className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4`}
            >
              <Audiences
                setAdsetData={setAdsetData}
                adsetData={adsetData}
                fb_token={fb_token}
              />
            </div>
            <div
              className={`flex flex-col ${getSummaryBgClass()} rounded-lg py-3 px-4`}
            >
              <label htmlFor="" className="text-[16px] font-semibold my-3">
                Placements
              </label>
              <p>Choose where your ad appears across Meta technologies</p>
              <ul className="pl-4 pt-4 w-1/2 px-4">
                {placementTypes?.map((objective) => {
                  return (
                    <li
                      className={`flex gap-4 items-center mb-8 group cursor-pointer px-2 py-2 rounded-lg  ${
                        selectedDetails?.label === objective?.label
                          ? "bg-[#C3DCF5]"
                          : ""
                      }`}
                      onClick={() => {
                        setSelectedDetails(objective);
                        setPlacements(true);
                      }}
                    >
                      {" "}
                      <div
                        className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                          selectedDetails?.label === objective?.label
                            ? "border-[5px]"
                            : "group-hover:border-[3px]"
                        }  `}
                      ></div>
                      <div className="flex-1">
                        <h3
                          className={`text-[14px] ${
                            currentMode === "dark"
                              ? selectedDetails?.label === objective?.label
                                ? "text-black"
                                : "text-white"
                              : "text-black"
                          }`}
                        >
                          {objective?.label}
                        </h3>
                        <p>{objective?.para}</p>
                      </div>
                    </li>
                  );
                })}
              </ul>
              {placements ? (
                <>
                  <label htmlFor="" className="text-[14px] font-semibold my-3">
                    Devices
                  </label>
                  <div className="w-full ">
                    <Select
                      // isLoading={adSetLoading}
                      id="goals"
                      options={devices}
                      value={devices?.filter((device) =>
                        adsetData?.targeting?.device_platforms?.includes(
                          device?.value
                        )
                      )}
                      onChange={(selectedOptions) => {
                        const devicesIds =
                          selectedOptions?.map((option) => option.value) || [];
                        setAdsetData((prevData) => ({
                          ...prevData,
                          targeting: {
                            ...prevData.targeting, // Correctly spreads the existing targeting object
                            device_platforms: devicesIds, // Updates the `device_platforms` key
                          },
                        }));
                      }}
                      placeholder={"All devices (recommended)"}
                      isMulti={true} // Enables multi-select
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                  </div>
                  <label htmlFor="" className="text-[14px] font-semibold my-3">
                    Platforms
                  </label>
                  <FormGroup>
                    <div className="grid grid-cols-2">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedPlatforms.includes("facebook")}
                            onChange={handlePlatformChange}
                            name="facebook"
                          />
                        }
                        label="Facebook"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedPlatforms.includes("instagram")}
                            onChange={handlePlatformChange}
                            name="instagram"
                          />
                        }
                        label="Instagram"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedPlatforms.includes(
                              "audience_network"
                            )}
                            onChange={handlePlatformChange}
                            name="audience_network"
                          />
                        }
                        label="Audience Network"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedPlatforms.includes("messenger")}
                            onChange={handlePlatformChange}
                            name="messenger"
                          />
                        }
                        label="Messenger"
                      />
                    </div>
                  </FormGroup>

                  <label htmlFor="" className="text-[14px] font-semibold my-3">
                    Asset customisation
                  </label>
                  <p className="text-gray-400">
                    18/18 placements that support asset customisation
                  </p>
                  <label htmlFor="" className="text-[14px] font-semibold my-3">
                    Placements
                  </label>
                  <Placements
                    adsetData={adsetData}
                    setAdsetData={setAdsetData}
                  />
                  {/* {adsetData?.targeting?.device_platforms?.includes(
                    "mobile"
                  ) ? (
                    <>
                      <label
                        htmlFor=""
                        className="text-[14px] font-semibold my-3"
                      >
                        Specific mobile devices & operating systems
                      </label>
                      <div className="w-full ">
                        <Select
                          // isLoading={adSetLoading}
                          id="goals"
                          options={mobileDevices}
                          value={mobileDevices?.find((dev) => dev.value == os)}
                          onChange={(selectedOption) => {
                            console.log("os e::: ", selectedOption);
                            setOS(selectedOption.value);
                            setAdsetData((prevData) => ({
                              ...prevData,
                              targeting: {
                                ...prevData.targeting,
                                user_os:
                                  selectedOption.value === "all"
                                    ? ["Andriod", "iOS"]
                                    : [selectedOption.value],
                              },
                            }));
                          }}
                          placeholder={"Select Mobile Device"}
                          // className={`mb-5`}
                          menuPortalTarget={document.body}
                          styles={selectStyles(currentMode, primaryColor)}
                        />
                      </div>
                      {os == "all" ? null : (
                        <>
                          <label
                            htmlFor=""
                            className="text-[14px] font-semibold text-gray-400 my-3"
                          >
                            Included devices
                          </label>
                          {includedDevice?.length > 0 && (
                            <div
                              className={`${
                                currentMode === "dark"
                                  ? "bg-gray-700"
                                  : "bg-[#F2F2F2]"
                              } rounded-lg py-3 px-4`}
                            >
                              {includedDevice?.map((inc, index) => (
                                <div
                                  key={index}
                                  className={`${
                                    currentMode === "dark"
                                      ? "bg-black"
                                      : "bg-white"
                                  } flex justify-between items-center px-3 rounded-lg py-3 my-3 group`}
                                >
                                  <div className="flex gap-4 items-center">
                                    {inc?.label}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}

                          <div className="flex items-center gap-4 border-b py-5 px-7 flex-1 mb-5">
                            <Select
                              options={devicesResult}
                              value={includedDevice}
                              onChange={(options) =>
                                handleSelectDevices(options, "include")
                              }
                              onMenuOpen={() => {
                                if (devicesResult?.length > 0) {
                                  return;
                                }
                                fetchTargetingDevices(
                                  "adTargetingCategory",
                                  "include",
                                  "user_device",
                                  setDevicesResult
                                );
                              }}
                              placeholder="Search Mobiles"
                              isLoading={loadingData?.include}
                              isMulti={true}
                              menuPortalTarget={document.body}
                              styles={selectStyles(currentMode, primaryColor)}
                            />
                          </div>
                          <label
                            htmlFor=""
                            className="text-[14px] font-semibold text-gray-400 my-3"
                          >
                            Excluded devices
                          </label>
                          {excludedDevice?.length > 0 && (
                            <div
                              className={`${
                                currentMode === "dark"
                                  ? "bg-gray-700"
                                  : "bg-[#F2F2F2]"
                              } rounded-lg py-3 px-4`}
                            >
                              {excludedDevice?.map((inc, index) => (
                                <div
                                  key={index}
                                  className={`${
                                    currentMode === "dark"
                                      ? "bg-black"
                                      : "bg-white"
                                  } flex justify-between items-center px-3 rounded-lg py-3 my-3 group`}
                                >
                                  <div className="flex gap-4 items-center">
                                    {inc?.label}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                          <div className="flex items-center gap-4 border-b py-5 px-7 flex-1 mb-5">
                            <Select
                              options={devicesResult}
                              value={excludedDevice}
                              onChange={(options) =>
                                handleSelectDevices(options, "exclude")
                              }
                              onMenuOpen={() => {
                                if (devicesResult?.length > 0) {
                                  return;
                                }
                                fetchTargetingDevices(
                                  "adTargetingCategory",
                                  "exclude",
                                  "user_device",
                                  setDevicesResult
                                );
                              }}
                              placeholder="Search Mobiles"
                              isLoading={loadingData?.include}
                              isMulti={true}
                              menuPortalTarget={document.body}
                              styles={selectStyles(currentMode, primaryColor)}
                            />
                          </div>
                          <label
                            htmlFor=""
                            className="text-[14px] font-semibold text-gray-400 my-3"
                          >
                            OS versions
                          </label>
                          <div className="grid grid-cols-2 w-[250px] gap-3">
                            <Select
                              options={osVersionsResult}
                              value={
                                minVersion
                                  ? osVersionsResult?.find(
                                      (versions) =>
                                        versions?.label == minVersion?.label
                                    )
                                  : null
                              }
                              onChange={(options) =>
                                handleSelectVersion(options, "min")
                              }
                              onMenuOpen={() => {
                                if (osVersionsResult?.length > 0) {
                                  return;
                                }
                                fetchOSVersions(
                                  "adTargetingCategory",
                                  "min",
                                  "user_os",
                                  setVersionResult
                                );
                              }}
                              placeholder="Min"
                              isLoading={loadingData?.min}
                              // isMulti={true}
                              menuPortalTarget={document.body}
                              styles={selectStyles(currentMode, primaryColor)}
                            />
                            <Select
                              options={osVersionsResult}
                              value={
                                maxVersion
                                  ? osVersionsResult?.find(
                                      (version) =>
                                        version?.label == maxVersion?.label
                                    )
                                  : null
                              }
                              onChange={(options) =>
                                handleSelectVersion(options, "max")
                              }
                              onMenuOpen={() => {
                                if (osVersionsResult?.length > 0) {
                                  return;
                                }
                                fetchOSVersions(
                                  "adTargetingCategory",
                                  "max",
                                  "user_os",
                                  setVersionResult
                                );
                              }}
                              placeholder="Max"
                              isLoading={loadingData?.max}
                              // isMulti={true}
                              menuPortalTarget={document.body}
                              styles={selectStyles(currentMode, primaryColor)}
                            />
                          </div>
                        </>
                      )}
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={adsetData?.connection_type == "wifi"}
                              onChange={(e) => {
                                setAdsetData((prev) => ({
                                  ...prev,
                                  connection_type: e.target.checked
                                    ? "wifi"
                                    : null,
                                }));
                              }}
                            />
                          }
                          label="Only when connected to Wi-Fi"
                        />
                      </div>
                    </>
                  ) : null} */}

                  <label htmlFor="" className="text-[10px] font-semibold">
                    Exclude skippable ads
                  </label>
                  <div className="flex gap-3">
                    <span>Available for</span>
                    <span className="font-semibold text-[10px]">
                      in-stream videos.
                    </span>
                  </div>

                  <div>
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          checked={adsetData?.targeting?.exclusions?.publisher_categories?.includes(
                            "SKIPPABLE_VIDEO_ADS"
                          )}
                          onChange={(event) => {
                            const isChecked = event.target.checked;

                            setAdsetData((prevData) => {
                              const currentExclusions =
                                prevData?.targeting?.exclusions
                                  ?.publisher_categories || [];

                              return {
                                ...prevData,
                                targeting: {
                                  ...prevData.targeting,
                                  exclusions: {
                                    ...prevData?.targeting?.exclusions,
                                    publisher_categories: isChecked
                                      ? [
                                          ...currentExclusions,
                                          "SKIPPABLE_VIDEO_ADS",
                                        ]
                                      : currentExclusions.filter(
                                          (category) =>
                                            category !== "SKIPPABLE_VIDEO_ADS"
                                        ),
                                  },
                                },
                              };
                            });
                          }}
                        />
                      }
                      label="Exclude skippable ads in the ad set (only for in-stream videos)"
                    /> */}

                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            adsetData?.targeting?.video_settings
                              ?.skip_unavailable_ads
                          }
                          onChange={(event) => {
                            const isChecked = event.target.checked;
                            setAdsetData((prevData) => ({
                              ...prevData,
                              targeting: {
                                ...prevData.targeting,
                                video_settings: {
                                  ...prevData?.targeting?.video_settings,
                                  skip_unavailable_ads: isChecked,
                                },
                              },
                            }));
                          }}
                        />
                      }
                      label="Exclude skippable ads in the ad set (only for in-stream videos)"
                    /> */}
                  </div>
                </>
              ) : null}
              <label htmlFor="" className="text-[16px] font-semibold  my-3">
                Brand safety and suitability
              </label>
              <p>
                Prevent your ads from appearing near content that's not
                conducive to your brand. Manage in Brand safety and suitability
              </p>
              <label htmlFor="" className="text-[14px] font-semibold  my-3">
                Inventory filter
              </label>
              <div className="bg-[#C3DCF5] rounded-lg p-3 mt-3">
                <div
                  className={`flex justify-between items-center py-3 text-black`}
                >
                  <p>
                    Tell us the duration of your anticipated high-demand period
                  </p>
                  <span>
                    <IoIosArrowUp size={16} />
                  </span>
                </div>
                <ul
                  className={`pl-4 pt-4 w-full px-4 grid grid-cols-3 gap-11 ${
                    currentMode === "dark" ? "bg-dark" : "bg-white"
                  }`}
                >
                  {inventoryFilters?.map((filter) => {
                    return (
                      <li
                        key={filter.value}
                        className={`flex gap-4 flex-col items-start mb-8 group cursor-pointer px-2 py-2 rounded-lg border  ${
                          selectedDetails?.label === filter?.label
                            ? "border-[#0A78BE]"
                            : ""
                        }`}
                        onClick={() => handleInventoryChange(filter)} // Handle inventory change on click
                      >
                        <div
                          className={`w-[16px] h-[16px] rounded-full border border-[#0A78BE] ${
                            selectedDetails?.label === filter?.label
                              ? "border-[5px]"
                              : "group-hover:border-[3px]"
                          }`}
                        ></div>
                        <div className="flex-1">
                          <h3
                            className={`text-[14px] ${
                              currentMode === "dark"
                                ? "text-white"
                                : "text-black"
                            }`}
                          >
                            {filter?.label}
                          </h3>
                          <p>{filter?.para}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div
              className={`flex justify-end py-5 ${getSummaryBgClass()} shadow-md px-4 mt-3`}
              // style={{ width: "calc(100% - 40px)" }}
            >
              <div className="flex items-center justify-end gap-3 w-full pt-3 border-t-[2px]">
                <button
                  // onClick={() => setCreateAdSetModal(false)}
                  className={`p-3 px-5 hover:border-[#F7D901] rounded-[5px] border font-medium ${
                    currentMode == "dark" ? "bg-dark text-white" : "bg-white"
                  } `}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    // setCreateAdSetModal(false);
                    createAdSet();
                    // setIsSingleAdSet(1);
                    // setAdSetDetails(null);
                  }}
                  className="p-3 px-7 rounded-[5px]  text-white bg-[#0A78BE]  border hover:border-[#F7D901]"
                  // disabled={!selectedCampaign}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <TimeScheduleView anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};

export default SingleAdSet;
