import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Switch } from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { MdOutlineDelete } from "react-icons/md";
import DeleteConfirm from "../snapchatMarketing/DeleteConfirmation";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import CustomSwitch from "./customSwitch";

const token = localStorage?.getItem("auth-token");

const deliveryStatus = [
  {
    code: "INVALID_NOT_EFFECTIVE_ACTIVE",
    label: "Ad is inactive",
  },
  {
    code: "INVALID_NOT_ACTIVE",
    label: "Not Devlivering",
  },
];

const AdsTable = ({
  setAdDetails,
  setIsSingleAd,
  ads,
  setAdSets,
  getAllAds,
  selectedRows,
  setSelectedRows,
  loading,
}) => {
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    DataGridStyles,
  } = useStateContext();
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);

  async function deleteAd(id) {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/delete-ads`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      getAllAds();
      toast.success("Ad is successfully deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log(error, "error");
      toast.error("Ad can't be deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  async function updateAd(adDetails, status) {
    try {
      await axios.post(
        `${BACKEND_URL}/snapchat/update-ads`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
          id: adDetails?.id,
          status: status ? "ACTIVE" : "PAUSED",
          name: adDetails?.name,
          ad_squad_id: adDetails?.ad_squad_id,
          creative_id: adDetails?.creative_id,
          type: adDetails?.type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      toast.success("Successfully Updated Ad", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      getAllAds();
      setIsSingleAd(false);
    } catch (error) {
      toast.error("Unable to Update Ad", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(error, "this is an error");
      if (error.response?.data?.errors) {
        Object.values(error.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    } finally {
    }
  }

  // useEffect(() => {
  //   if (snapchatCredentials?.access_token) {
  //     getAllAds();
  //   }
  // }, [snapchatCredentials]);

  const columns = [
    {
      field: "status",
      headerName: "Off/On",
      headerAlign: "center",
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      minWidth: 150,
      renderCell: (params) => (
        <CustomSwitch
          checked={params.row.status == "PAUSED" ? false : true}
          onChange={(e) => updateAd(params?.row, e?.target?.checked)}
        />
      ),
    },
    // {
    //   field: "delivery_status",
    //   headerName: "Delivery",
    //   headerAlign: "center",
    //   minWidth: 200,
    //   renderHeader: (params) => {
    //     return <div className=" font-semibold">{params.colDef.headerName}</div>;
    //   },
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params?.value?.map(
    //           (val) =>
    //             deliveryStatus?.find((status) => status?.code == val)
    //               ?.label && (
    //               <div>
    //                 {
    //                   deliveryStatus?.find((status) => status?.code == val)
    //                     ?.label
    //                 }
    //               </div>
    //             )
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "name",
      headerName: "Ad",
      headerAlign: "left",
      headerClassName: "pl-5",
      renderHeader: (params) => {
        return (
          <div className="pl-12 font-semibold">{params.colDef.headerName}</div>
        );
      },
      renderCell: (params) => (
        <Name
          value={params?.value}
          onEdit={() => {
            setAdDetails(params?.row);
            setIsSingleAd(true);
          }}
          onDelete={() => {
            // deleteAdSet(params?.row?.id);
            setOpenDeleteConfirm({ id: params?.row?.id, name: params?.value });
          }}
        />
      ),
      minWidth: 250,
    },

    {
      field: "optimization_goal",
      headerName: "Goal",
      headerAlign: "center",
      minWidth: 200,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return <div>{params?.value}</div>;
      },
    },
    {
      field: "cpc",
      headerName: "CPC",
      headerAlign: "center",
      minWidth: 200,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return <div>{params?.value}</div>;
      },
    },

    {
      field: "reach",
      headerName: "Reach",
      headerAlign: "center",
      minWidth: 200,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return <div>{params?.value}</div>;
      },
    },
    {
      field: "impressions",
      headerName: "Impressions",
      headerAlign: "center",
      minWidth: 200,
      renderHeader: (params) => {
        return <div className=" font-semibold">{params.colDef.headerName}</div>;
      },
      renderCell: (params) => {
        return <div>{params?.value}</div>;
      },
    },
  ];

  return (
    <>
      <div>
        <Box
          className={`closed-datatable ${currentMode}-mode-datatable`}
          sx={{
            // ...DataGridStyles,
            ...DataGridStyles,
            "& .MuiDataGrid-columnHeaders": {
              ...DataGridStyles["& .MuiDataGrid-columnHeaders"],
              backgroundColor: "transparent",
              color: currentMode === "dark" ? "white" : "black",
              fontFamily:
                "Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif",
              borderTop: "1px solid rgba(224, 224, 224, 1)",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            },
            "& .MuiButtonBase-root": {
              ...DataGridStyles["& .MuiButtonBase-root"],
              fontFamily:
                "Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif",
            },
            "& .MuiDataGrid-virtualScroller": {
              ...DataGridStyles["& .MuiDataGrid-virtualScroller"],
              fontFamily:
                "Graphik, Avenir Next, Segoe UI, Times New Roman, sans-serif",
            },
            "& .MuiDataGrid-cell": {
              borderRight: "1px solid #e5e7eb",
            },
            "& .MuiDataGrid-columnHeader": {
              borderRight: "1px solid #e5e7eb",
            },
            ".MuiDataGrid-columnHeaders": {
              paddingLeft: "3px", // Apply padding here
            },
            position: "relative",
            marginBottom: "50px",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <DataGrid
            rows={ads}
            columns={columns}
            loading={loading}
            pageSize={5}
            checkboxSelection
            disableSelectionOnClick
            autoHeight
            selectionModel={selectedRows?.ads}
            onSelectionModelChange={(rows) =>
              setSelectedRows((pre) => ({ ...pre, ads: rows }))
            }
          />
        </Box>
      </div>
      <DeleteConfirm
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        message={` Are you sure you want to delete ad ${openDeleteConfirm?.name}? `}
        onDelete={deleteAd}
        title="Confirm Delete Ad"
      />
    </>
  );
};

export default AdsTable;

const Name = ({ value, onEdit, onDelete }) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const { currentMode } = useStateContext();
  return (
    <>
      <div
        className="pl-11 w-full text-left"
        onMouseEnter={() => setIsMouseEnter(true)}
        onMouseLeave={() => setIsMouseEnter(false)}
      >
        <p>{value}</p>
        {isMouseEnter && (
          <div className="flex gap-3">
            <button
              // className="mt-2 text-gray-600 hover:text-gray-900"
              className={`mt-2 ${
                currentMode === "dark"
                  ? "text-white hover:text-gray-600"
                  : "text-gray-600 hover:text-gray-900"
              } `}
              onClick={onEdit}
            >
              Edit
            </button>
            <button
              // className="mt-2 text-gray-600 hover:text-gray-900 flex gap-2 items-center"
              className={`mt-2 ${
                currentMode === "dark"
                  ? "text-white hover:text-gray-600"
                  : "text-gray-600 hover:text-gray-900"
              }  flex gap-2 items-center`}
              onClick={onDelete}
            >
              Delete <MdOutlineDelete size={16} />
            </button>
          </div>
        )}
      </div>
    </>
  );
};
